import React from "react";
import Loadable from "react-loadable";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = () => ({
  extraTools: {
    flex: 1
  }
});
const ToolBarTools = ({ classes, location }) => (
  <div className={classes.extraTools}>
    <Route
      render={() => {
        if (location.pathname === "/") {
          const DashboardHeader = Loadable({
            loader: () =>
              import(/* webpackChunkName: "js/counters" */ "./DashHeader"),
            loading: () => <p>loading...</p>
          });
          return <DashboardHeader />;
        }
        if (location.pathname.startsWith("/application/")) {
          const AppNavigation = Loadable({
            loader: () =>
              import(/* webpackChunkName: "js/appnavs" */ "./ApplicationNavigation"),
            loading: () => <p>loading...</p>
          });
          return <AppNavigation />;
        }
        return null;
      }}
    />
  </div>
);
const mapStateToProps = state => ({
  location: state.router.location
});

ToolBarTools.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({}).isRequired
};

export default connect(mapStateToProps)(withStyles(styles)(ToolBarTools));

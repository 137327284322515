/*eslint-disable*/
import React, { Component } from "react";
import PropTypes from "prop-types";

export default class HeadyAway extends Component {
  static propTypes = {
    account: PropTypes.string.isRequired,
    selector: PropTypes.string.isRequired
  };
  static displayName = "Headaway";
  componentDidMount() {
    const { account, selector } = this.props;
    if (!window.Headway) return;
    window.Headway.destroy();
    window.Headway.init({
      selector,
      account
    });
  }
  componentWillMount() {
    const { account, selector } = this.props;
    if (!window.Headway) return;
    window.Headway.destroy();
  }
  shouldComponentUpdate() {
    if (!window.Headway) return;
    window.Headway.destroy();
    return true;
  }

  componentWillUnmount() {
    if (!window.Headway) return;
    window.Headway.destroy();
  }
  componentWillReceiveProps() {
    if (!window.Headway) return;
    window.Headway.destroy();
  }
  componentWillUpdate() {
    if (!window.Headway) return;
    window.Headway.destroy();
  }
  render() {
    if (window.Headway) {
      window.Headway.destroy();
    }
    return <span id="headway" />;
  }
}

import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
// import Unarchive from "@material-ui/icons/Unarchive";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
// import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import moment from "moment";
import numeral from "numeral";
import { checkFreePlan } from "Utils/helpers";
import { openAccountSettings } from "Store/modules/common/accountSettings/actions";

const styles = theme => ({
  snackbar: {
    backgroundColor: "#E91E63",
    maxWidth: "none",
    width: "100%",
    justifyContent: "flex-start",
    fontSize: "11pt",
    padding: "12px 12px",
    cursor: "pointer",
    borderRadius: 0
  },
  button: {
    fontWeight: "600!important"
  },
  rightIcon: {
    marginRight: theme.spacing.unit,
    marginTop: -2
  },
  snackbarContent: {
    display: "flex",
    alignItems: "center",
    "& p": {
      flex: "1 1 0",
      color: theme.palette.primary.contrastText,
      paddingRight: theme.spacing.unit * 4,
      fontSize: 14
    },
    "& button": {
      color: theme.palette.primary.contrastText,
      fontSize: 13
    },
    "@media(max-width: 768px)": {
      flexDirection: "column",
      "& p": {
        paddingRight: 0,
        paddingBottom: theme.spacing.unit
      }
    }
  },
  trialMessage: {
    textAlign: "center",
    backgroundColor: "#E91E63",
    padding: `${theme.spacing.unit}px 0 0 !important`,
    margin: `${theme.spacing.unit}px 0 0`,
    "& p": {
      margin: `0 0 ${theme.spacing.unit}px`
    }
  },
  progress: {
    borderBottom: "1px solid #e91e63",
    backgroundColor: "#fff",
    height: "5px",
    "& .progress-bar": {
      height: "100%",
      backgroundColor: "#f970a1"
    }
  }
});
/* eslint-disable func-names */
/* eslint-disable react/no-danger */
class Statistics extends React.Component {
  componentDidMount() {
    // $(".animate-number").each(function() {
    //   $(this).animateNumbers(
    //     $(this).attr("data-value"),
    //     true,
    //     parseInt($(this).attr("data-animation-duration"), 10)
    //   );
    // });
    // this.animatePrecentage();
  }

  componentDidUpdate() {
    // $(".animate-number").each(function() {
    //   $(this).animateNumbers(
    //     $(this).attr("data-value"),
    //     true,
    //     parseInt($(this).attr("data-animation-duration"), 10)
    //   );
    // });
    // this.animatePrecentage();
  }

  animatePrecentage() {
    const total = this.props.reqs[0]
      ? parseInt(this.props.reqs[0].replace(/,/g, ""), 10)
      : 0;
    return total;
    // const consumed = this.props.apiRequests;
    // const percentage = (consumed / total * 100).toFixed(1);
    // $("#percentage").animateNumbers(
    //   percentage,
    //   true,
    //   parseInt($("#percentage").attr("data-animation-duration"), 10)
    // );
    // $("#percentagebar").css("width", percentage);
    // $("#consumed").text(consumed);
  }

  renderOldStatistics = () => {
    const { props } = this;
    return (
      <Grid item xs={12} key={10}>
        <Grid container spacing={16}>
          <Grid item xs={12} key={1}>
            <div
              className="usageinfo pull-right"
              dangerouslySetInnerHTML={{
                __html: props.user.fd
                  ? ` * API requests and number of notifications statistics start from <strong>${moment(
                      props.user.fd.sec
                    ).format("M d, Y")}</strong>`
                  : ""
              }}
            />
          </Grid>

          <Grid item xs={12} key={2}>
            <Grid container spacing={16}>
              {props.devices > 0 && (
                <Grid item xs={12} sm={3}>
                  <div className="tiles red">
                    <div className="tiles-body">
                      <div className="tiles-title">
                        <i className="fa fa-mobile-alt" /> TOTAL USERS
                      </div>
                      <div className="heading">
                        <span
                          className="animate-number"
                          data-value={numeral(props.devices).format("0,0")}
                          data-animation-duration="1200"
                        >
                          0
                        </span>
                      </div>
                      <div className="progress transparent progress-white progress-small no-radius" />
                      <div>
                        <br />
                      </div>
                    </div>
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={3}>
                <div className="tiles blue">
                  <div className="tiles-body">
                    <div className="tiles-title">
                      <i className="fa fa-globe" /> API REQUESTS *
                    </div>
                    <div className="heading">
                      <span
                        className="animate-number"
                        id="percentage"
                        data-animation-duration="1200"
                      >
                        0
                      </span>
                      %
                    </div>
                    <div className="progress transparent progress-small no-radius">
                      <div
                        className="progress-bar progress-bar-white animate-progress-bar"
                        id="percentagebar"
                      />
                    </div>
                    <div>
                      <span id="consumed">
                        {numeral(props.reqs[1]).format("0,0")}
                      </span>{" "}
                      of{" "}
                      <em>
                        <span id="totalapi">{props.reqs[0]}</span>
                      </em>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className="tiles green">
                  <div className="tiles-body">
                    <div className="tiles-title">
                      <i className="fa fa-envelope" /> NOTIFICATIONS SENT *
                    </div>
                    <div className="heading">
                      <span
                        className="animate-number"
                        data-value={
                          props.pushes[2] === "&infin;"
                            ? "∞"
                            : numeral(props.pushes[2]).format("0,0")
                        }
                        data-animation-duration="1000"
                      >
                        0
                      </span>
                      %
                    </div>
                    <div className="progress transparent progress-small no-radius">
                      <div
                        className="progress-bar progress-bar-white animate-progress-bar"
                        data-percentage={`${
                          props.pushes[2] === "&infin;" ? "∞" : props.pushes[2]
                        }%`}
                      />
                    </div>
                    <div>
                      {props.pushes[1]} of{" "}
                      <em>
                        {props.pushes[0] === "&infin;" ? "∞" : props.pushes[0]}
                      </em>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className="tiles purple">
                  <div className="tiles-body">
                    <div className="tiles-title">
                      <i className="fa fa-asterisk" /> TOTAL APPS
                    </div>
                    <div className="row-fluid">
                      <div className="heading">
                        <span
                          className="animate-number"
                          data-value={numeral(props.totalApps).format("0,0")}
                          data-animation-duration="700"
                        >
                          0
                        </span>
                      </div>
                      <div className="progress transparent progress-white progress-small no-radius" />
                      <div>
                        {" "}
                        of <em>unlimited</em>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderDevicesLimitAlert = () => {
    const { classes, openAccSettings } = this.props;

    return (
      <Grid item xs={12} key={11}>
        <div
          className={classes.snackbar}
          onClick={() => {
            openAccSettings("Payment");
          }}
          message=""
          id="limitAlert"
        >
          <div className="container">
            <div className={classes.snackbarContent}>
              <Typography variant="body1">
                <strong>Action Required:</strong> We have tried to charge you
                couple of times with no luck. Please, add a new payment method
                to avoid service interruption.
              </Typography>
              <Button
                variant="text"
                className={classes.button}
                key="Upgrade Now"
              >
                {/* <Unarchive className={classes.rightIcon} /> */}
                <i className={`${classes.rightIcon} fa fa-credit-card`} />
                ADD A NEW PAYMENT METHOD
              </Button>
            </div>
          </div>
        </div>
      </Grid>
    );
  };

  renderUserStatistics = () => {
    const { props } = this;
    return props.devices <= 1000 ? (
      <Grid item xs={12} sm={6} key={12}>
        <div className="tiles green">
          <div className="tiles-body">
            <div className="tiles-title">
              <i className="fa fa-mobile-alt" /> TOTAL USERS
            </div>
            <div className="heading">
              <span
                className="animate-number"
                data-value={Math.round((props.devices / 1000) * 100)}
                data-animation-duration="1200"
              >
                0
              </span>{" "}
              %
            </div>
            <div className="progress transparent progress-white progress-small no-radius">
              <div
                className="progress-bar progress-bar-white animate-progress-bar"
                data-percentage={`${Math.round((props.devices / 1000) * 100)}%`}
              />
            </div>
            <div>
              <strong>{numeral(props.devices).format("0,0")}</strong> of 1,000
            </div>
          </div>
        </div>
      </Grid>
    ) : (
      <Grid item xs={12} sm={6} key={13}>
        <div className="tiles green">
          <div className="tiles-body">
            <div className="tiles-title">
              <i className="fa fa-mobile-alt" /> TOTAL USERS
            </div>

            <div className="heading">
              <span
                className="animate-number"
                data-value="100"
                data-animation-duration="1200"
              >
                0
              </span>{" "}
              %
            </div>
            <div className="progress transparent progress-white progress-small no-radius">
              <div
                className="progress-bar progress-bar-white animate-progress-bar"
                data-percentage={`${Math.round((props.devices / 1000) * 100)}%`}
              />
            </div>
            <div>
              {props.devices === 1 ? "1 user" : `${props.devices} users`}
              <a
                href="#Upgrade"
                className="btn btn-warning pull-right"
                style={{
                  padding: "3px 12px 4px",
                  textTransform: "uppercase",
                  fontSize: "12px"
                }}
              >
                Upgrade now
              </a>
            </div>
          </div>
        </div>
      </Grid>
    );
  };

  renderProUsersStatistics = () => {
    const { props } = this;
    return (
      <Grid item xs={12} sm={6} key={14}>
        <div className="tiles green">
          <div className="tiles-body">
            <div className="tiles-title">
              <i className="fa fa-mobile-alt" /> TOTAL USERS
            </div>
            <div className="heading">
              <span
                className="animate-number"
                data-value={numeral(props.devices).format("0,0")}
                data-animation-duration="1200"
              >
                0
              </span>
            </div>
            <div className="progress transparent progress-white progress-small no-radius" />
            <div>
              <br />
            </div>
          </div>
        </div>
      </Grid>
    );
  };

  renderAppsStatistics = () => {
    const { totalApps } = this.props;
    return (
      <Grid item xs={12} sm={6} key={15}>
        <div className="tiles purple">
          <div className="tiles-body">
            <div className="tiles-title">
              <i className="fa fa-asterisk" /> TOTAL APPS
            </div>
            <div className="row-fluid">
              <div className="heading">
                <span
                  className="animate-number"
                  data-value={numeral(totalApps).format("0,0")}
                  data-animation-duration="700"
                >
                  0
                </span>
              </div>
              <div className="progress transparent progress-white progress-small no-radius" />
              <div>
                {" "}
                of <em>unlimited</em>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    );
  };

  renderTrial = () => {
    const { props } = this;
    const { classes } = props;
    let diff = moment(props.trial_expires_on).diff(moment());
    if (diff <= 0) diff = 0;
    console.log("diff:", diff);
    const remaining = Math.round(moment.duration(diff).asDays());
    const percentage = Math.round((remaining / 14) * 100);
    return (
      <Grid
        item
        xs={12}
        sm={12}
        key={16}
        justify="center"
        className={classes.trialMessage}
      >
        <p>
          <strong>{remaining} days</strong> remaining on trial
        </p>
        {percentage ? (
          <div className={classes.progress}>
            <div
              className="progress-bar progress-bar-success"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${percentage}%` }}
            />
          </div>
        ) : null}
      </Grid>
    );
  };

  render() {
    const { props } = this;

    // const grids = [];

    // if (!props.old_plan && !props.freePlan && props.trial) {
    //   grids.push(this.renderTrial());
    // }

    // if (props.old_plan) {
    //   grids.push(this.renderOldStatistics());
    // }

    // !props.old_plan && props.freePlan
    //   ? grids.push(this.renderUserStatistics())
    //   : grids.push(this.renderProUsersStatistics());

    // if (!props.old_plan) {
    //   grids.push(this.renderAppsStatistics());
    // }

    return (
      <Grid container spacing={16}>
        {/* <Hidden smDown>
          <Grid item xs={12} key={1}>
            <Counters
              oldPlan={props.old_plan}
              devices={props.devices}
              pushes={props.pushes}
              reqs={props.reqs}
              totalApps={props.totalApps}
            />
          </Grid>
        </Hidden> */}

        {/* {props.old_plan && (
        <Grid item xs={12} key={1}>
          <div
            style={{ fontSize: 10 }}
            className="usageinfo pull-right"
            dangerouslySetInnerHTML={{
              __html: props.user.fd
                ? ` * API limits will reset <strong>${moment(props.user.fd.sec)
                    .add(1, "months")
                    .from(moment())}</strong>`
                : ""
            }}
          />
        </Grid>
       )}  */}
        {!props.old_plan &&
          !props.freePlan &&
          props.trial &&
          this.renderTrial()}
        {props.lockDashboard && this.renderDevicesLimitAlert()}
      </Grid>
    );
  }
}
Statistics.propTypes = {
  classes: PropTypes.object.isRequired,
  reqs: PropTypes.array.isRequired,
  totalApps: PropTypes.number.isRequired,
  openAccSettings: PropTypes.func.isRequired
  // apiRequests: PropTypes.number.isRequired
};
const mapSateToProps = ({ dashboard = {}, auth }) => ({
  devices: dashboard.devices,
  pushes: dashboard.pushes,
  reqs: dashboard.reqs || [],
  totalApps: dashboard.totalApps || 0,
  apiRequests: dashboard.apiRequests,
  user: auth.user,
  trial: auth.user.trial_status,
  trial_expires_on: auth.user.trial_expires_on,
  old_plan: auth.old_plan,
  freePlan: checkFreePlan(auth.user.plan_id),
  lockDashboard: auth.user.lock_dashboard
});

const mapDispatchToProps = dispatch => ({
  openAccSettings: payload => dispatch(openAccountSettings(payload))
});

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(withStyles(styles)(Statistics));

import Loadable from "react-loadable";
import { injectReducer } from "../../store/makeRootReducer";
import PlaceHolder from "./components/PlaceHolder";

export default store =>
  Loadable({
    loader: () =>
      injectReducer(store, {
        key: "userbase",
        getReducer: () =>
          import(/* webpackChunkName: "js/userbaseReducer" */ "./modules/reducers")
      }).then(() =>
        import(/* webpackChunkName: "js/userbase" */ "./containers/UserBase.jsx")
      ),
    loading: PlaceHolder
  });

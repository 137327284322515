import React from "react";
import Grid from "@material-ui/core/Grid";
import { RectShape } from "react-placeholder/lib/placeholders";
import BlockUi from "react-block-ui";
import PropTypes from "prop-types";

const PlaceHolder = ({ route }) => (
  <BlockUi
    blocking
    tag="div"
    loader={
      <div className="spinner">
        <div className="double-bounce1" />
        <div className="double-bounce2" />
      </div>
    }
  >
    <Grid container justify="center" spacing={40}>
      {/* header container  */}
      <Grid item sm={12} md={12}>
        <Grid container spacing={40} justify="center">
          <Grid item xs={4} sm={4} md={4}>
            <RectShape
              color="lightgray"
              style={{ width: "100%", height: "150px" }}
            />
          </Grid>
        </Grid>
      </Grid>
      {route.indexOf("abnotification") > -1 ? (
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={10}>
              <RectShape
                color="lightgray"
                style={{ width: "100%", height: "500px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item xs={8} sm={8} md={8}>
            <RectShape
              color="lightgray"
              style={{ width: "100%", height: "500px" }}
            />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  </BlockUi>
);

PlaceHolder.propTypes = {
  route: PropTypes.string
};

PlaceHolder.defaultProps = {
  route: ""
};

export default PlaceHolder;

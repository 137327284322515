import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import FontAwesome from "react-fontawesome";
import SocialLogin from "react-provider-login";
import classNames from "classnames";
import Button from "@material-ui/core/Button";

const styles = () => ({
  socialBtn: {
    borderRadius: 0
  }
});

const GithubButton = ({ classes, triggerLogin, ...props }) => (
  <Button
    variant="outlined"
    size="large"
    onClick={triggerLogin}
    className={classNames(classes.socialBtn)}
    {...props}
  >
    <i className="fab fa-github fa-lg" />
    &nbsp;Create a PushBots account with GitHub
  </Button>
);

GithubButton.propTypes = {
  classes: PropTypes.object.isRequired,
  triggerLogin: PropTypes.func.isRequired
};

export default SocialLogin(withStyles(styles)(GithubButton));

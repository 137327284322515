import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";

const ConfirmDialog = props => (
  <Dialog
    style={{ zIndex: props.zIndex }}
    onClose={props.onCancel}
    open={props.open}
    data-test={`${props.testId}-dialog`}
  >
    <DialogTitle data-test={`${props.testId}-title`}>{props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText data-test={`${props.testId}-message`}>
        {props.message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        color={props.cancelBtnColor}
        onClick={props.onCancel}
        data-test={`${props.testId}-cancel`}
      >
        {props.cancelLabel || "Cancel"}
      </Button>
      <Button
        autoFocus
        color={props.okBtnColor}
        onClick={() => {
          if (props.onOk) {
            props.onOk();
          }
          if (props.onConfirm) {
            props.onConfirm();
          }
        }}
        data-test={`${props.testId}-ok`}
      >
        {props.okLabel || "Ok"}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.propTypes = {
  cancelLabel: PropTypes.string,
  message: PropTypes.string.isRequired,
  okLabel: PropTypes.any,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onOk: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  cancelBtnColor: PropTypes.string,
  okBtnColor: PropTypes.string,
  testId: PropTypes.string,
  zIndex: PropTypes.number
};
ConfirmDialog.defaultProps = {
  zIndex: 1400,
  onOk: null,
  onConfirm: null,
  cancelLabel: "Cancel",
  okLabel: "Ok",
  cancelBtnColor: "secondary",
  okBtnColor: "primary",
  testId: "confirmation-dialog"
};
export default ConfirmDialog;

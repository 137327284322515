import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ExitToApp from "@material-ui/icons/ExitToApp";
// import HistoryIcon from "@material-ui/icons/History";
import Settings from "@material-ui/icons/Settings";
import { withRouter } from "react-router-dom";
import MoreVert from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";
import Gravatar from "react-gravatar";
import { connect } from "react-redux";
import { get } from "lodash";
import moment from "moment";

const styles = () => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  avatar: {
    margin: 10
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  userName: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    color: "inherit"
  },
  menuToggle: {},
  menuItem: {},
  primary: {
    padding: "0 8px",
    "& span": {
      fontSize: ".8em"
    }
  },
  icon: {
    margin: "1px 0 0 0",
    fontSize: "17px"
  },
  gravatar: {
    borderRadius: "200px 200px 200px 200px",
    textAlign: "right",
    display: "block",
    margin: "0 0 0 1em",
    maxWidth: "100%"
  },
  crown: {
    backgroundColor: "#423833",
    borderRadius: 10,
    display: "flex",
    height: 21,
    color: "#ffffff",
    padding: "5px 10px 3px",
    fontSize: ".8rem",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 4,
    "& > svg": {
      width: 18,
      marginRight: 3,
      marginTop: -3
    }
  },
  github: {
    // backgroundColor: "#FFC107",
    backgroundColor: "#FF9800",
    "& i": {
      marginRight: 3,
      marginTop: -3,
      transform: "scale(.9)"
    },
    "& span": {
      marginTop: -2
    }
  }
});

class ProfileBar extends React.PureComponent {
  state = {
    anchorEl: null
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      user,
      className,
      logoutUser,
      openAccountSettings
      // userFullHash
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const github_student = get(this.props, "user.github_student");
    const github_teacher = get(this.props, "user.github_teacher");
    let planActive = false;
    let badgeContent = null;
    let badgeText = "";

    if (github_student) {
      const expirationDate = moment(github_student).add(6, "M");
      const currentDate = moment();
      planActive = expirationDate > currentDate;
      badgeText = "Student";
    }

    if (github_teacher) {
      const expirationDate = moment(github_teacher).add(6, "M");
      const currentDate = moment();
      planActive = expirationDate > currentDate;
      badgeText = "Teacher";
    }

    if (planActive) {
      badgeContent = (
        <div className={`${classes.crown} ${classes.github}`}>
          <i className="fab fa-github fa-lg" />
          <span>{badgeText}</span>
        </div>
      );
    } else if (user.plan_id !== "free") {
      badgeContent = (
        <div className={classes.crown}>
          <svg viewBox="0 0 156.745 156.745">
            <g>
              <path
                d="M155.656,49.94c-0.97-0.722-2.299-0.717-3.259,0.01l-43.321,33.072L80.844,19.236c-0.864-1.954-4.07-1.954-4.936,0   L47.332,83.772L4.364,49.991c-0.949-0.762-2.299-0.772-3.262-0.056c-0.988,0.73-1.36,2.009-0.917,3.148l28.907,74.669   c0.4,1.039,1.402,1.725,2.515,1.725h93.542c1.113,0,2.12-0.686,2.516-1.725l28.904-74.669   C156.996,51.944,156.626,50.67,155.656,49.94z M77.635,42.22L52.489,97.472c-0.277,0.602-0.81,1.045-1.458,1.203   c-0.169,0.042-0.335,0.062-0.52,0.062c-0.475,0-0.944-0.152-1.326-0.453L17.632,73.636c-0.939-0.74-1.107-2.101-0.372-3.034   c0.738-0.939,2.088-1.113,3.035-0.367l29.392,22.966l24.002-52.763c0.499-1.092,1.788-1.562,2.864-1.076   C77.654,39.855,78.134,41.134,77.635,42.22z M124.722,138.974H32.018c-1.485,0-2.7-1.207-2.7-2.699c0-1.482,1.215-2.7,2.7-2.7   h92.704c1.481,0,2.7,1.218,2.7,2.7C127.422,137.767,126.204,138.974,124.722,138.974z"
                fill="#ffffff"
              />
            </g>
          </svg>
          <span>Premium</span>
        </div>
      );
    } else {
      badgeContent = <span>Free</span>;
    }
    return (
      <div className={className}>
        <Hidden smDown>
          <div className={classes.userName}>
            <Typography color="inherit">
              <strong>{user.name}</strong>
            </Typography>
            {badgeContent}
          </div>
        </Hidden>
        {user.picture ? (
          <Avatar
            data-test="profile-avatar-pic"
            alt={user.name}
            src={user.picture}
            className={classes.avatar}
          />
        ) : (
          <Gravatar
            data-test="profile-gravatar-pic"
            size={50}
            className={classes.gravatar}
            email={user.email}
          />
        )}

        <IconButton
          data-test="account-menu"
          aria-owns={open ? "menu-appbar" : null}
          aria-haspopup="true"
          className={classes.menuToggle}
          onClick={this.handleMenu}
          color="inherit"
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem
            data-test="account-menu-settings"
            className={classes.menuItem}
            onClick={e => {
              openAccountSettings("AccountSettings");
              this.handleClose(e);
            }}
          >
            <ListItemIcon className={classes.icon}>
              <Settings />
            </ListItemIcon>
            <ListItemText
              classes={{ root: classes.primary }}
              inset
              primary="Account Settings"
            />
          </MenuItem>

          <MenuItem
            data-test="account-menu-logout"
            className={classes.menuItem}
            onClick={e => {
              logoutUser();
              this.handleClose(e);
            }}
          >
            <ListItemIcon className={classes.icon}>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText
              classes={{ root: classes.primary }}
              inset
              primary="Logout"
            />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

ProfileBar.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  logoutUser: PropTypes.func.isRequired,
  openAccountSettings: PropTypes.func.isRequired
  // userFullHash: PropTypes.string.isRequired
};
ProfileBar.defaultProps = {
  className: null
};
const mapStateToProps = state => ({
  user: state.auth.user,
  userFullHash: state.auth.userFullHash
});
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(ProfileBar))
);

import auth from "./auth/reducers";
import modal from "./modal/reducers";
import notifications from "./notifications/reducers";
import accountSettings from "./accountSettings/reducers";
import trial from "./trial/reducers";
import ui from "./ui/reducers";
import search from "./search/reducers";
import appSettings from "./appSettings/reducers";
import taskRunner from "./taskRunner/reducers";

export default {
  auth,
  modal,
  accountSettings,
  notifications,
  trial,
  ui,
  search,
  appSettings,
  taskRunner
};

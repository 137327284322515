import initialState from "Store/initialState";
import * as actionTypes from "./actionTypes";

export default (state = initialState.auth, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.AUTHENTICATE:
      return {
        ...state,
        ...payload
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.payload
      };
    case actionTypes.EMAIL_SENT:
      return {
        ...state,
        isResetEmailSent: action.payload
      };
    case actionTypes.LOCKING:
      return {
        ...state,
        lock: action.payload
      };
    case actionTypes.FETCHING_AUTH_DATA:
      return {
        ...state,
        fetchingAuthData: payload
      };
    case actionTypes.SET_CLEARBIT_DATA:
      return {
        ...state,
        clearbitData: payload
      };
    case actionTypes.SET_LOGIN_SOURCE:
      return {
        ...state,
        loginSource: payload
      };
    default:
      return state;
  }
};

export default {
  baseURL: "/api",
  auth: {
    login: "/auth/login",
    logout: "/auth/logout",
    register: "/auth/register",
    refresh: "/auth/refresh",
    email: "/auth/forget-password",
    reset: "/auth/reset-password",
    me: "/auth/me",
    socialLogin: (socialProvider) => `/auth/handle/${socialProvider}`,
  },
  sendSinglePush: "https://api.pushbots.com/3/push/transactional",
  pushEndPoint: "/application/campaign/",
  abtestingPushEndPoint: (appId) => `/application/${appId}/abtest`,
  inAppPushEndPoint: (appId) => `/application/${appId}/inappcampaign`,
  presetsEndPoint: "/application/preset/",
  dashboardEndPoint: "/",
  trialEndPoint: "/account/trial",
  checkSdkVersion: (appId) => `application/version/${appId}`,
  chartNotAnalytics: ({
    analyticsId,
    platform,
    startDate,
    endDate,
    chartType = "daily",
  }) =>
    `/application/notificationanalytics/${analyticsId}?platform=${platform}&start=${startDate}&end=${endDate}&type=${chartType}`,
  deviceStats: (appId, platform) =>
    `/application/deviceStats/${appId}/${platform}`,

  // Notifications reports
  loadingNotification: (notificationId) =>
    `/application/notification/${notificationId}`,
  getABtestReport: (abtestId) => `/application/abtest/${abtestId}`,
  getInAppReport: (notificationId) => `/application/inapp/${notificationId}`,

  // App History List routes
  getChistoryList: (appId, pageNum, rowsNum) =>
    `/application/chistory/${appId}/?page=${pageNum}&rows=${rowsNum}`,
  getAPIhistoryList: (appId, pageNum, rowsNum) =>
    `/application/apihistory/${appId}/?page=${pageNum}&rows=${rowsNum}`,
  getOnehistoryList: (appId, pageNum, rowsNum) =>
    `/application/onehistory/${appId}/?page=${pageNum}&rows=${rowsNum}`,
  getPollhistoryList: (appId, pageNum, rowsNum) =>
    `/application/pollhistory/${appId}/?page=${pageNum}&rows=${rowsNum}`,
  getABTestinghistoryList: (appId, pageNum, rowsNum) =>
    `/application/${appId}/abtests/?page=${pageNum}&rows=${rowsNum}`,
  getInApphistoryList: (appId, pageNum, rowsNum) =>
    `/application/${appId}/inapps/?page=${pageNum}&rows=${rowsNum}`,
  getABTestingProgress: (abtestId) =>
    `/application/abtest/${abtestId}/progress`,

  updateDevice: (deviceId) => `/application/device/update/${deviceId}`,
  toggleTestDevice: (appId) => `/application/devices/${appId}/testdevice`,
  deleteDevice: (appId) => `/application/devices/${appId}/delete`,
  paginateDevices: (paginateObj) =>
    `/application/devices/${paginateObj.appId}/paginate?take=${
      paginateObj.pageSize
    }&skip=${paginateObj.pageSize * paginateObj.currentPage}${
      paginateObj.filter
    }&orderby=${paginateObj.sortColumnName}&direction=${
      paginateObj.direction
    }#ajax=${Math.random() * 100000}`,
  paginateFilteredDevices: (paginateObj) =>
    `/application/${paginateObj.appId}/devices/filter?take=${
      paginateObj.pageSize
    }&skip=${paginateObj.pageSize * paginateObj.currentPage}&${
      paginateObj.filter
    }&orderby=${paginateObj.sortColumnName}&direction=${
      paginateObj.direction
    }#ajax=${Math.random() * 100000}`,
  exportCSV: (paginateObj) =>
    `/application/${paginateObj.appId}/devices/export/csv?take=${
      paginateObj.pageSize
    }&skip=${paginateObj.pageSize * paginateObj.currentPage}&${
      paginateObj.filter
    }&orderby=${paginateObj.sortColumnName}&direction=${
      paginateObj.direction
    }#ajax=${Math.random() * 100000}`,
  getTargetingData: (appId) => `/application/targeting/${appId}`,
  getReach: (appId, query) => `/application/reach/${appId}?${query}`,
  profileUpdate: `/account/update`,
  updatePassword: "/account/update-password",
  addCreditCard: "/account/billing/cc/add",
  listCards: "/account/billing",
  deleteCard: (cardId) => `/account/billing/cc/del/${cardId}`,
  defaultCard: (cardId) => `/account/billing/cc/default/${cardId}`,
  getInvoices: (page) => `/account/invoices?page=${page}`,
  changeProfilePic: (userId) => `/account/picture/${userId}`,
  removePic: (userId) => `/account/picture/${userId}/delete`,
  planSub: (id) => `/account/billing/subscribe/${id}`,
  getUserAccount: `/account`,
  unsubPlan: `/account/unsubscribe`,
  getActiveUsers: (startDate, endDate) =>
    `/application/active-users?start-date=${startDate}&end-date=${endDate}`,
  prodApps: (appId) => `/application/prod-apps/${appId}`,
  activities: "/get_activties",
  destroyApp: "/application/destroy",
  editData: (appId) => `/application/edit/${appId}?json=yes`,
  deleteApp: "application/destroy",
  addColl: (appId) => `application/collabrators/${appId}`,
  collPermission: (appId) => `application/collabrators/${appId}/permissions`,
  delColl: (appId, col_userid) =>
    `application/collabrators/${appId}/delete/${col_userid}`,
  unlinkTwitter: (userId) => `application/unlink/twitter/${userId}`,
  appTotalAnalytics: (appId, startDate, endDate, platform) =>
    `application/analytics/${appId}/?start=${startDate}&end=${endDate}&platform=${platform}`,
  loadingAnalytics: (appId) => `application/stats/${appId}`,
  getApp: (appId) => `get_app/${appId}`,
  newApp: "new_app",
  fetchSearchAppsByTitle: (title) => `/application/search?title=${title}`,
  fetchInvoiceById: (id) => `/account/invoice/print/${id}`,
  delNotification: (appId, notId) =>
    `/application/notification/del/${appId}/${notId}`,
  getAppEvents: (appId) => `/application/${appId}/devices/activities/ids`,
  uploadImage: "/application/uploadimage",
  accountImages: "/account/images",
  clearbit: (email) => `/emaillookup/${email}`,
  paginateSentData: (
    id,
    take = 10,
    skip = 0,
    orderby = "token",
    direction = "desc"
  ) =>
    `/application/notification/${id}/devices/sent?take=${take}&skip=${skip}&orderby=${orderby}&direction=${direction}`,
  paginateOpenedData: (
    id,
    take = 10,
    skip = 0,
    orderby = "token",
    direction = "desc"
  ) =>
    `/application/notification/${id}/devices/open?take=${take}&skip=${skip}&orderby=${orderby}&direction=${direction}`,
  getGeoData: (notiId) =>
    `/application/notification/${notiId}/devices/open/geo`,
};

import React from "react";
import Grid from "@material-ui/core/Grid";
import { RectShape } from "react-placeholder/lib/placeholders";
import BlockUi from "react-block-ui";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
// import get from "lodash/get";
import { connect } from "react-redux";

const styles = () => ({
  loading: {
    textAlign: "center"
  }
});

const PlaceHolder = props => {
  const { classes } = props;

  return (
    <BlockUi
      blocking
      tag="div"
      className="show-loading-animation"
      loader={
        <div className={classes.loading}>
          <div className="spinner">
            <div className="double-bounce1" />
            <div className="double-bounce2" />
          </div>
          {/* <img
            alt="Loading.."
            src={`/assets/svg/${get(loading, "spinnerName", "oval")
              .split(" ")
              .join("")}.svg`}
          />
          <h2>{get(loading, "message", "Loading...")}</h2> */}
        </div>
      }
    >
      <Grid container spacing={16} className="show-loading-animation">
        <Grid item xs={12}>
          <RectShape
            color="lightgray"
            style={{ width: "100%", height: "400px" }}
          />
        </Grid>
      </Grid>
    </BlockUi>
  );
};

PlaceHolder.propTypes = {
  classes: PropTypes.object.isRequired
  // loading: PropTypes.object
};

PlaceHolder.defaultProps = {
  // loading: {
  //   spinnerName: "oval",
  //   message: "We’ll be right with you…"
  // }
};
const mapStateToProps = state => ({
  loading: state.auth.user.loading
});
export default connect(mapStateToProps)(withStyles(styles)(PlaceHolder));

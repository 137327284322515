// import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import { teal } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

export const createTheme = (color = teal) =>
  createMuiTheme({
    overrides: {
      MuiButton: {
        raisedPrimary: {
          color: "#fff"
        }
      }
    },
    palette: {
      primary: {
        light: color[500],
        main: color[700],
        dark: color[900],
        contrastText: "#fff"
      }
    },
    typography: {
      useNextVariants: true
    }
  });
export default createTheme;

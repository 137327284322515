export const LOADING = "ACCOUNT_SETTINGS_LOADING";
export const LOCKING = "ACCOUNT_SETTINGS_LOCKING";
export const PROFILE_LOCKING = "PROFILE_SETTINGS_LOCKING";
export const SET_DATA = "SET_DATA";
export const SET_STATS = "SET_STATS";
export const SET_PLATFORM_STATS = "SET_PLATFORM_STATS";
export const SET_CREDIT_TOKEN = "SET_CREDIT_TOKEN";
export const SET_CREDIT_CARD = "SET_CREDIT_CARD";
export const SET_CARDS_LIST = "SET_CARDS_LIST";
export const SET_TAB_VALUE = "SET_TAB_VALUE";
export const SET_INVOICES_LIST = "SET_INVOICES_LIST";
export const SET_PAGINATE_DATA = "SET_PAGINATE_DATA";
export const SET_PIC_URL = "SET_PIC_URL";
export const TOGGLE_UPGRADE_DIALOG = "TOGGLE_UPGRADE_DIALOG";
export const ADD_AND_UPGRADE = "ADD_AND_UPGRADE";
export const ACCOUNT_MODAL_TOGGLE = "ACCOUNT_MODAL_TOGGLE";
export const SAVE_HASH = "SAVE_HASH";
export const SET_BILLING_EMAILS_STATE = "SET_BILLING_EMAILS_STATE";

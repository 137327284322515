import initialState from "Store/initialState";
import * as actionTypes from "./actionTypes";

const rootReducer = (state = initialState.accountSettings, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionTypes.LOCKING:
      return {
        ...state,
        lock: action.payload
      };
    case actionTypes.PROFILE_LOCKING:
      return {
        ...state,
        profilelock: action.payload
      };

    case actionTypes.ADD_AND_UPGRADE:
      return {
        ...state,
        addUpgradeObj: action.payload
      };
    case actionTypes.SET_CARDS_LIST:
      return {
        ...state,
        cards: action.payload
      };
    case actionTypes.SET_TAB_VALUE:
      return {
        ...state,
        tab: action.payload
      };
    case actionTypes.SET_PAGINATE_DATA:
      return {
        ...state,
        paginationData: action.payload
      };

    case actionTypes.SET_PIC_URL:
      return {
        ...state,
        profilePicUrl: action.payload
      };
    case actionTypes.SET_INVOICES_LIST:
      return {
        ...state,
        invoices: action.payload
      };
    case actionTypes.SET_CREDIT_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case actionTypes.TOGGLE_UPGRADE_DIALOG:
      return {
        ...state,
        upgradeConfirmDialog: action.payload
      };
    case actionTypes.ACCOUNT_MODAL_TOGGLE:
      return {
        ...state,
        accountModalStatus: action.payload
      };
    case actionTypes.SAVE_HASH:
      return {
        ...state,
        hash: action.payload
      };
    case actionTypes.SET_BILLING_EMAILS_STATE:
      return {
        ...state,
        billingEmails: action.payload
      };
    default:
      return state;
  }
};

export default rootReducer;

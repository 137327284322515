import * as actionTypes from "./actionTypes";

export const openModal = payload => ({
  type: actionTypes.OPEN_MODAL,
  payload
});
export const closeModal = payload => ({
  type: actionTypes.CLOSE_MODAL,
  payload
});

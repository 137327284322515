import initialState from "Store/initialState";
import * as actionTypes from "./actionTypes";

export default (state = initialState.search, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SAVE_APPS:
      return {
        ...state,
        apps: [...payload]
      };
    default:
      return state;
  }
};

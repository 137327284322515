import initialState from "Store/initialState";
import * as actionTypes from "./actionTypes";

export default (state = initialState.taskRunner, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.START_RUNNER:
      return {
        ...state,
        tasks: state.tasks.concat(payload)
      };
    case actionTypes.STOP_RUNNER:
      return {
        ...state,
        tasks: state.tasks.filter(t => t.name !== payload)
      };
    default:
      return state;
  }
};

import Loadable from "react-loadable";
import { injectReducer } from "../../store/makeRootReducer";
import PlaceHolder from "./components/PlaceHolder";

export default store =>
  Loadable({
    loader: () =>
      injectReducer(store, {
        key: "accountInvoice",
        getReducer: () =>
          import(/* webpackChunkName: "js/invoiceReducer" */ "./modules/reducers")
      }).then(() =>
        import(/* webpackChunkName: "js/invoice" */ "./containers/Invoice.jsx")
      ),
    loading: PlaceHolder
  });

import initialState from "../../../initialState";
import * as actionTypes from "./actionTypes";

const rootReducer = (state = initialState.appSettings, action) => {
  switch (action.type) {
    case actionTypes.INIT:
      return state;
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionTypes.SET_TAB_VALUE:
      return {
        ...state,
        tab: action.payload
      };
    case actionTypes.LOCKING:
      return {
        ...state,
        lock: action.payload
      };
    case actionTypes.SET_APP_DATA:
      return {
        ...state,
        app: action.payload
      };

    case actionTypes.SET_APP_ICON:
      return {
        ...state,
        icon: action.payload
      };
    case actionTypes.SET_WEB_BLOCKING:
      return {
        ...state,
        webBlock: action.payload
      };

    default:
      return state;
  }
};

export default rootReducer;

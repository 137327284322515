import * as actionTypes from "./actionTypes";

export function createNotification(notification) {
  return {
    type: actionTypes.ADD_NOTIFICATION,
    notification: {
      ...notification,
      id: Date.now()
    }
  };
}

export function removeNotification(id) {
  return {
    type: actionTypes.REMOVE_NOTIFICATION,
    id
  };
}

export function removeAllNotifications(force) {
  return {
    type: actionTypes.REMOVE_ALL_NOTIFICATIONS,
    force
  };
}

import { enableBatching } from "redux-batched-actions";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { createNotification } from "Store/modules/common/notifications/actions";
import common from "./modules/common";

/* eslint-disable no-param-reassign */

const createHistory = require("history").createBrowserHistory;

export const history = createHistory();

const combineLazyReducers = (reducers, initialState) => {
  const reducerKeys = new Set(Object.keys(reducers));
  Object.keys(initialState)
    .filter(k => !reducerKeys.has(k))
    .forEach(k => {
      reducers[k] = state => (state === undefined ? null : state);
    });
  return combineReducers(reducers);
};

const makeRootReducer = (asyncReducers = {}, state) =>
  enableBatching(
    connectRouter(history)(
      combineLazyReducers(
        {
          ...common,
          ...asyncReducers
        },
        state
      )
    )
  );

export const injectReducer = (store, { key, getReducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) {
    return Promise.resolve();
  }
  return getReducer()
    .then(module => {
      store.asyncReducers[key] = module.default;
      store.replaceReducer(
        makeRootReducer(store.asyncReducers, store.getState())
      );
    })
    .catch(() => {
      const arr = store.getState().notifications;
      if (arr && arr.length === 0) {
        store.dispatch(
          createNotification({
            color: "danger",
            autoHideDuration: 10000,
            message:
              "You either disconnected from the internet or PushBots is in maintenance mode, Please refresh the page."
          })
        );
      }
    });
};

export default makeRootReducer;

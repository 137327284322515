import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HomeIcon from "@material-ui/icons/Home";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import StarIcon from "@material-ui/icons/Star";
import PaymentIcon from "@material-ui/icons/Payment";
import HistoryIcon from "@material-ui/icons/History";
// import LockIcon from "@material-ui/icons/Lock";
import IPhoneIcon from "@material-ui/icons/PhoneIphone";
import AndroidIcon from "@material-ui/icons/Android";
import SmartPhoneIcon from "@material-ui/icons/Smartphone";
import CloudIcon from "@material-ui/icons/Cloud";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const styles = () => ({
  menuItemText: {
    "& > span": {
      fontSize: ".8em"
    }
  },
  icon: {
    margin: 0,
    minWidth: 22,
    textAlign: "center"
  },
  showOnMobile: {
    display: "none",
    "@media(max-width: 599px)": {
      display: "flex"
    }
  }
});

const NavigationList = ({
  history,
  logoutUser,
  openAccountSettings,
  classes,
  currentAppId,
  currentPath,
  currentAppDevices
}) => (
  <div>
    {currentAppId && (
      <>
        <ListItem
          button
          className={classes.showOnMobile}
          onClick={() => history.push(`/application/show/${currentAppId}`)}
        >
          <ListItemIcon className={classes.icon}>
            <i className="fa fa-lg fa-users" />
          </ListItemIcon>
          <ListItemText className={classes.menuItemText} primary="Userbase" />
        </ListItem>
        <ListItem
          button
          className={classes.showOnMobile}
          onClick={() =>
            history.push(
              `/application/devices/${currentAppId}?condition=and&ls=30&lsoperator=greaterthan&lsoperatorIndex=3`
            )
          }
        >
          <ListItemIcon className={classes.icon}>
            <i className="fa fa-lg fa-mobile-alt" />
          </ListItemIcon>
          <ListItemText className={classes.menuItemText} primary="Customers" />
        </ListItem>
        <ListItem
          button
          className={classes.showOnMobile}
          onClick={() => history.push(`/application/chistory/${currentAppId}`)}
        >
          <ListItemIcon className={classes.icon}>
            <i className="fa fa-lg fa-history" />
          </ListItemIcon>
          <ListItemText className={classes.menuItemText} primary="Delivery" />
        </ListItem>
        <ListItem
          button
          className={classes.showOnMobile}
          onClick={() =>
            history.push(
              currentAppDevices
                ? `/application/campaign/${currentAppId}`
                : currentPath
            )
          }
        >
          <ListItemIcon className={classes.icon}>
            <i className="fa fa-lg fa-paper-plane" />
          </ListItemIcon>
          <ListItemText className={classes.menuItemText} primary="Push" />
        </ListItem>
        <ListItem
          button
          className={classes.showOnMobile}
          onClick={() => history.push(`/application/edit/${currentAppId}`)}
        >
          <ListItemIcon className={classes.icon}>
            <i className="fa fa-lg fa-cog" />
          </ListItemIcon>
          <ListItemText className={classes.menuItemText} primary="Settings" />
        </ListItem>
        <Divider className={classes.showOnMobile} />
      </>
    )}

    <ListItem button onClick={() => history.push("/")}>
      <ListItemIcon className={classes.icon}>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText className={classes.menuItemText} primary="Dashboard" />
    </ListItem>
    {/* <ListItem button onClick={() => history.push("/certificates")}>
      <ListItemIcon className={classes.icon}>
        <CheckCircleIcon />
      </ListItemIcon>
      <ListItemText className={classes.menuItemText} primary="Certificates" />
    </ListItem> */}
    <ListItem button onClick={() => openAccountSettings("AccountSettings")}>
      <ListItemIcon className={classes.icon}>
        <AccountBoxIcon />
      </ListItemIcon>
      <ListItemText className={classes.menuItemText} primary="Profile" />
    </ListItem>
    <ListItem button onClick={() => openAccountSettings("Upgrade")}>
      <ListItemIcon className={classes.icon}>
        <StarIcon />
      </ListItemIcon>
      <ListItemText className={classes.menuItemText} primary="Upgrade" />
    </ListItem>
    <ListItem button onClick={() => openAccountSettings("Payment")}>
      <ListItemIcon className={classes.icon}>
        <PaymentIcon />
      </ListItemIcon>
      <ListItemText className={classes.menuItemText} primary="Payment" />
    </ListItem>
    <ListItem button onClick={() => openAccountSettings("Bills")}>
      <ListItemIcon className={classes.icon}>
        <HistoryIcon />
      </ListItemIcon>
      <ListItemText className={classes.menuItemText} primary="Invoices" />
    </ListItem>
    {/* <ListItem button onClick={() => openAccountSettings("AccountSettings")}>
      <ListItemIcon className={classes.icon}>
        <LockIcon />
      </ListItemIcon>
      <ListItemText className={classes.menuItemText} primary="Password" />
    </ListItem> */}
    <Divider />
    <ListItem
      button
      onClick={() =>
        window.open(
          "https://www.pushbots.help/install-pushbots-in-your-app-or-website/ios/getting-started-with-ios-push-notifications",
          "_blank"
        )
      }
    >
      <ListItemIcon className={classes.icon}>
        <IPhoneIcon />
      </ListItemIcon>
      <ListItemText className={classes.menuItemText} primary="Ios docs" />
    </ListItem>
    <ListItem
      button
      onClick={() =>
        window.open(
          "https://www.pushbots.help/install-pushbots-in-your-app-or-website/android/getting-started-with-android-push-notifications",
          "_blank"
        )
      }
    >
      <ListItemIcon className={classes.icon}>
        <AndroidIcon />
      </ListItemIcon>
      <ListItemText className={classes.menuItemText} primary="Android docs" />
    </ListItem>
    <ListItem
      button
      onClick={() =>
        window.open(
          "https://www.pushbots.help/install-pushbots-in-your-app-or-website/cordova-phonegap-and-phonegap-build/getting-started-with-phonegapcordova",
          "_blank"
        )
      }
    >
      <ListItemIcon className={classes.icon}>
        <SmartPhoneIcon />
      </ListItemIcon>
      <ListItemText className={classes.menuItemText} primary="Cordova docs" />
    </ListItem>
    <ListItem
      button
      onClick={() =>
        window.open("https://developer.pushbots.com/docs", "_blank")
      }
    >
      <ListItemIcon className={classes.icon}>
        <CloudIcon />
      </ListItemIcon>
      <ListItemText className={classes.menuItemText} primary="API Docs" />
    </ListItem>
    <Divider />
    <ListItem button onClick={() => logoutUser()}>
      <ListItemIcon className={classes.icon}>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText className={classes.menuItemText} primary="Logout" />
    </ListItem>
  </div>
);

NavigationList.propTypes = {
  history: PropTypes.object.isRequired,
  openAccountSettings: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  currentAppId: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  currentAppDevices: PropTypes.number.isRequired
};

export default withStyles(styles)(withRouter(NavigationList));

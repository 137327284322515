import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const styles = theme => ({
  lockedImg: {
    maxWidth: "500px",
    display: "block",
    margin: "0 auto",
    width: "100%"
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    padding: `58px ${theme.spacing.unit * 6}px`
  },
  lockedButton: {
    marginTop: theme.spacing.unit
  },
  title: {
    marginBottom: theme.spacing.unit * 3
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 2
  },
  body: {
    lineHeight: "1.8"
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    marginTop: -5
  },
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing.unit * 3,
    "& button": {
      marginRight: theme.spacing.unit
    }
  },
  list: {
    "& i": {
      color: green[500]
    }
  },
  listItem: {
    paddingBottom: theme.spacing.unit / 2
  }
});

const PaidSupport = props => {
  const { classes } = props;

  return (
    <div className={`${classes.container}`}>
      <Grid container alignItems="center" spacing={24}>
        <Grid item xs={12} lg={6}>
          <div className={classes.lockedText}>
            <Typography className={classes.title} variant="h4">
              You are on limited support.
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              gutterBottom
            >
              We would love to support every one! However, to offer you a
              quality and priority support, we have to restrict priority support
              for our pro customers.
            </Typography>
            <Typography className={classes.body} variant="body2" gutterBottom>
              If you upgrade to pro you will enjoy the following:
            </Typography>
            <List dense className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemText>
                  <i className="fa fa-check" /> Priority support (same-day
                  support).
                </ListItemText>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText>
                  <i className="fa fa-check" /> Code assistance.
                </ListItemText>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText>
                  <i className="fa fa-check" /> User onboarding and account
                  setup.
                </ListItemText>
              </ListItem>
            </List>
            <div className={classes.buttons}>
              <Button
                className={classes.lockedButton}
                variant="text"
                color="primary"
                onClick={() => props.showAccountSettings("Upgrade")}
              >
                <i className={`fas fa-crown ${classes.buttonIcon}`} />
                Upgrade to pro
              </Button>
              <Button
                className={classes.lockedButton}
                variant="text"
                color="secondary"
                onClick={() => {
                  props.setModalOpened(false);
                  if (!window.Intercom) return;
                  window.Intercom("update", {
                    hide_default_launcher: false
                  });
                  window.Intercom("showNewMessage");
                }}
              >
                Stay free and use limited support
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <img
            className={classes.lockedImg}
            src="images/paid-support.png"
            alt="Locked"
          />
        </Grid>
      </Grid>
    </div>
  );
};

PaidSupport.propTypes = {
  classes: PropTypes.object.isRequired,
  showAccountSettings: PropTypes.func.isRequired,
  setModalOpened: PropTypes.func.isRequired
};

export default withStyles(styles)(PaidSupport);

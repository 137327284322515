export default {
  sdkVersions: {
    poll: {
      web: "1.0.1",
      android: "3.1-beta",
      ios: "2.1-alpha"
    },
    inApp: {
      web: "1.0.1",
      android: "3.2.0",
      ios: "2.2.0"
    }
  }
};

import React from "react";
import { Provider, connect } from "react-redux";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { createTheme } from "Utils";
import { createNotification } from "Store/modules/common/notifications/actions";
import { loadUserFromToken } from "Store/modules/common/auth/actions";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { hot } from "react-hot-loader/root";
import GlobalPreloader from "Shared/GlobalPreloader";
import UpgradeBox from "Shared/UpgradeBox";
import configureStore from "../store";
import initialState from "../store/initialState";
import createRoutes from "./createRoutes";

export const { store, history } = configureStore(initialState);

if (window.ga) {
  window.ga("create", "UA-51748123-1", "auto");
  history.listen(location => {
    window.ga("set", "page", location.pathname + location.search);
    window.ga("send", "pageview", location.pathname + location.search);
  });
}

window.addEventListener("offline", () => {
  store.dispatch(
    createNotification({ message: "You lost your internet connection" })
  );
});

window.addEventListener("online", () => {
  store.dispatch(
    createNotification({
      message: "your internet connection is back, reloading..."
    })
  );
  window.location.reload();
});
class App extends React.PureComponent {
  componentWillMount() {
    this.props.loadUserFromToken();
  }

  render() {
    const { fetchingAuthData, themeColor } = this.props;
    const baseTheme = createTheme(themeColor);

    return fetchingAuthData ? (
      <GlobalPreloader />
    ) : (
      <MuiThemeProvider theme={baseTheme}>
        <CssBaseline />
        <ConnectedRouter history={history}>
          {createRoutes(store)}
        </ConnectedRouter>
        <UpgradeBox />
      </MuiThemeProvider>
    );
  }
}
App.propTypes = {
  loadUserFromToken: PropTypes.func.isRequired,
  fetchingAuthData: PropTypes.bool.isRequired,
  themeColor: PropTypes.shape({}).isRequired
};
const mapDispatchToProps = dispatch => ({
  loadUserFromToken: () => dispatch(loadUserFromToken())
});
const mapStateToProps = ({ auth, ui }) => ({
  fetchingAuthData: auth.fetchingAuthData,
  themeColor: ui.themeColor
});
const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

const hotApp = () => (
  <Provider store={store}>
    <AppContainer />
  </Provider>
);

export default hot(hotApp);

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { socialLogin } from "Store/modules/common/auth/actions";
import GithubButton from "./GithubButton";

const styles = theme => ({
  cont: {
    display: "flex",
    minHeight: "100vh",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  githubButton: {
    opacity: 0,
    visibility: "hidden"
  },
  title: {
    // "& span": {
    //   color: theme.palette.secondary.main
    // },
    "& i": {
      marginRight: 4
    }
  },
  progress: {
    marginBottom: theme.spacing.unit * 2
  }
});

const AutoGithub = props => {
  console.log(props);

  const { classes } = props;

  useEffect(() => {
    const el = document.getElementById("github-button");
    if (el) {
      setTimeout(() => {
        el.click();
      }, 1000);
    }
  });

  const handleSocialLogin = user => {
    if (user._provider === "google") {
      props.socialLogin(user._token.accessToken, "google");
    } else {
      props.socialLogin(user._token.accessToken, "github");
    }
  };

  const handleSocialLoginFailure = err => {
    console.error("loginn err ", err);
  };

  return (
    <div className={classes.cont}>
      <CircularProgress className={classes.progress} color="secondary" />
      <Typography className={classes.title} variant="h6">
        Logging you in with{" "}
        <span>
          <i className="fab fa-github" />
          GitHub
        </span>
        ...
      </Typography>
      <GithubButton
        provider="github"
        gatekeeper="/api"
        autoCleanUri={false}
        query={props.location.search}
        appId={process.env.GITHUB_CLIENTID}
        onLoginSuccess={handleSocialLogin}
        onLoginFailure={handleSocialLoginFailure}
        redirect={process.env.GITHUB_REDIRECT}
        id="github-button"
        className={classes.githubButton}
      />
    </div>
  );
};

AutoGithub.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  socialLogin: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth, notification }) => ({
  isAuthenticated: auth.isAuthenticated,
  notification,
  lock: !!auth.lock,
  user: auth.user || {}
});

const mapDispatchToProps = dispatch => ({
  socialLogin: (profile, socialProvider) =>
    dispatch(socialLogin(profile, socialProvider))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AutoGithub));

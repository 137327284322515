import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  title: {
    marginBottom: theme.spacing.unit * 4
  },
  img: {
    maxWidth: 250,
    maxHeight: 200,
    objectFit: "contain",
    width: "100%",
    display: "block",
    margin: "0 auto"
  }
});

const FeaturedImage = ({ classes }) => (
  <div>
    <Typography
      variant="h6"
      color="inherit"
      align="center"
      className={classes.title}
    >
      PushBots is featured as Top Push Notifications Service Provider.
    </Typography>
    <img
      src="images/featured.png"
      alt="Featured as a Top Push Notifications Service Provider"
      className={classes.img}
    />
  </div>
);

FeaturedImage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FeaturedImage);

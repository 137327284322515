import axios from "axios";
import axiosRetry from "axios-retry";
import { authenticate } from "Store/modules/common/auth/actions";
import { createNotification } from "Store/modules/common/notifications/actions";
import { TRIAL_STATUS_MESSAGE } from "Config/constants";
import { push, goBack } from "connected-react-router";
import { store } from "../containers/App";
import Api from "../api";

export const __TOKEN_KEY__ = "pushbotsAuthToken";

function logoutUser() {
  localStorage.removeItem(__TOKEN_KEY__);
  store.dispatch(authenticate({ isAuthenticated: false, redirect: true }));
  if (window.location.search) {
    store.dispatch(push(`/auth/login${window.location.search}`));
  } else {
    const redirectTo =
      window.location.pathname + (window.location.search || "");
    store.dispatch(
      push("/auth/login", {
        search: redirectTo ? `?redirectTo=${redirectTo}` : ""
      })
    );
  }
}

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";
axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const customRequest = axios.create({
  baseURL: Api.baseURL
});

customRequest.interceptors.request.use(
  config => {
    const token = localStorage.getItem(__TOKEN_KEY__);
    if (token != null) {
      return {
        ...config,
        headers: { ...config.headers, Authorization: `Bearer ${token}` }
      };
    }
    return config;
  },
  err => Promise.reject(err)
);

customRequest.interceptors.response.use(
  response => {
    // Check for the response code
    // 101, 103 ( The token has expired )
    // 401 ( Not authorized )
    // 404 ( Not found )
    // 422 ( Redirect )
    if (response && response.data && response.data.code) {
      if (response.data.code === 101 || response.data.code === 103) {
        logoutUser();
      } else if (response.data.code === 401) {
        const message = response.data.message || "Something went wrong";
        store.dispatch(createNotification({ message }));
        store.dispatch(goBack());
      } else if (response.data.code === 422) {
        const message = response.data.message || "Something went wrong";
        store.dispatch(createNotification({ message }));
        store.dispatch(goBack());
      } else if (response.data.code === 404) {
        const message = response.data.message || "Something went wrong";
        store.dispatch(createNotification({ message }));
      }
    }
    return response;
  },
  error => {
    const { response, request } = error;
    if (response && response.status === 401) {
      logoutUser();
    }

    if (response && response.status === 422) {
      const message =
        (response.data && response.data.message) || "Something went wrong";
      store.dispatch(goBack());
      store.dispatch(createNotification({ message }));
    }

    if (response && response.status === 404) {
      const message =
        (response.data && response.data.message) || "Something went wrong";
      store.dispatch(createNotification({ message }));
    }

    if (response && response.status === 500) {
      const arr = store.getState().notifications;
      switch (response.data.message) {
        case TRIAL_STATUS_MESSAGE:
          store.dispatch(push("/trial"));
          break;
        default:
          if (arr && arr.length === 0) {
            store.dispatch(
              createNotification({
                color: "danger",
                autoHideDuration: 10000,
                message:
                  "You either disconnected from the internet or PushBots is in maintenance mode, Please refresh the page."
              })
            );
          }
          console.log(response.data.message);
      }
    }
    if (request && !response) {
      const arr = store.getState().notifications;
      if (arr && arr.length === 0) {
        store.dispatch(
          createNotification({
            color: "danger",
            autoHideDuration: 10000,
            message:
              "You either disconnected from the internet or PushBots is in maintenance mode, Please refresh the page."
          })
        );
      }
    }

    return Promise.reject(error);
  }
);

axiosRetry(customRequest, { retryDelay: axiosRetry.exponentialDelay });

export const httpClient = {
  get: customRequest.get,
  post: customRequest.post,
  put: customRequest.put,
  delete: customRequest.delete,
  setAuthHeader: token => {
    customRequest.defaults.headers.Authorization = `Bearer ${token}`;
  }
};

export default httpClient;

import "react-hot-loader";
import React from "react";
import ReactDOM from "react-dom";
import "react-placeholder/lib/reactPlaceholder.css";
import "react-block-ui/style.css";
import App from "./containers/App";
// import registerServiceWorker from "./registerServiceWorker";

// registerServiceWorker(process.env.NODE_ENV === "production");

ReactDOM.render(<App />, document.getElementById("root"));

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import { setUpgradeBoxVisible } from "Store/modules/common/ui/actions";

const youtubeRegex = /(youtube(-nocookie)?\.com|youtu\.be)\/(watch\?v=|v\/|u\/|embed\/?)?([\w-]{11})(.*)?/i;
const vimeoRegex = /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/;

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const styles = theme => ({
  dialogRoot: {},
  content: {
    paddingTop: `${theme.spacing.unit * 6}px !important`,
    paddingBottom: theme.spacing.unit * 5
  },
  bottom: {
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    margin: 0,
    justifyContent: "space-between",
    color: "#ffffff",
    flexWrap: "wrap",
    "& > div": {
      padding: theme.spacing.unit
    }
  },
  rightBottom: {
    textAlign: "center"
  },
  mainThree: {
    display: "flex",
    flexWrap: "wrap",
    borderBottom: "1px solid rgba(0, 0, 0, .3)",
    paddingBottom: theme.spacing.unit * 5,
    textAlign: "center",
    "& > *": {
      flex: "1 1 0",
      padding: theme.spacing.unit,
      minWidth: 295,
      "@media(max-width: 430px)": {
        minWidth: "100%"
      }
    },
    "& i": {
      color: theme.palette.primary.main
    }
  },
  mainThreeIcon: {
    marginBottom: theme.spacing.unit * 2
  },
  featuresList: {
    padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px`
  },
  listItem: {
    "& i": {
      marginRight: theme.spacing.unit
    }
  },
  iframeCont: {
    padding: `${theme.spacing.unit * 4}px 0`
  },
  title: {
    lineHeight: 1.2,
    fontSize: "1.15rem"
  }
});

const UpgradeBox = props => {
  const { classes, theme, upgradeBoxVisible, upgradeBoxContent } = props;
  const {
    mainThree,
    featuresList,
    videoUrl,
    title,
    desc,
    price,
    submitButtonText,
    onClick
  } = upgradeBoxContent;

  function handleClose() {
    props.setUpgradeBoxVisible(false);
  }

  function handleSubmit() {
    onClick();
    handleClose();
  }

  function getIframe() {
    if (!videoUrl) return "";
    const ytMatches = youtubeRegex.exec(videoUrl);
    const viMatches = vimeoRegex.exec(videoUrl);
    if (ytMatches) {
      return `https://www.youtube${ytMatches[2] || ""}.com/embed/${
        ytMatches[4]
      }`;
    }
    if (viMatches) {
      return `https://player.vimeo.com/video/${viMatches[3]}`;
    }
    return videoUrl;
  }

  const iframeSrc = getIframe();

  return (
    <div>
      <Dialog
        open={upgradeBoxVisible}
        TransitionComponent={Transition}
        scroll="paper"
        maxWidth="md"
        keepMounted
        onClose={handleClose}
        aria-labelledby={title}
        aria-describedby={desc}
        className={classes.dialogRoot}
      >
        <DialogContent className={classes.content}>
          <div className={classes.mainThree}>
            {mainThree.map(item => (
              <div key={item.title}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.mainThreeIcon}
                >
                  <i
                    className={`${item.icon} fa-2x`}
                    style={{ "--fa-primary-color": theme.palette.primary.main }}
                  />
                </Typography>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  {item.title}
                </Typography>
                <Typography variant="body2">{item.desc}</Typography>
              </div>
            ))}
          </div>

          <Grid container>
            <Grid item xs={12} md={iframeSrc ? 7 : 12}>
              <div className={classes.featuresList}>
                <Typography variant="h5" gutterBottom>
                  What you get from {title}
                </Typography>
                <List dense aria-label="Feature list">
                  {featuresList.map(item => (
                    <ListItem key={item}>
                      <ListItemText
                        className={classes.listItem}
                        disableTypography
                      >
                        <Typography variant="body2">
                          <i
                            className="fad fa-check"
                            style={{
                              "--fa-primary-color": theme.palette.primary.main
                            }}
                          />{" "}
                          {item}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Grid>
            {iframeSrc && (
              <Grid item xs={12} md={5}>
                <div className={classes.iframeCont}>
                  <iframe
                    src={iframeSrc}
                    width="100%"
                    height="250"
                    title={`${title}intro`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.bottom}>
          <div>
            <Typography variant="h5" color="inherit">
              {title}
            </Typography>
            <Typography variant="body2" color="inherit">
              {desc}
            </Typography>
          </div>
          <div className={classes.rightBottom}>
            <Typography variant="h5" color="inherit" gutterBottom>
              ${price}/mo
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              {submitButtonText}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

UpgradeBox.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  upgradeBoxVisible: PropTypes.bool.isRequired,
  setUpgradeBoxVisible: PropTypes.func.isRequired,
  upgradeBoxContent: PropTypes.object.isRequired
};

const mapStateToProps = ({ ui }) => ({
  upgradeBoxVisible: ui.upgradeBoxVisible,
  upgradeBoxContent: ui.upgradeBoxContent
});

const mapDispatchToProps = dispatch => ({
  setUpgradeBoxVisible: payload => dispatch(setUpgradeBoxVisible(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(UpgradeBox));

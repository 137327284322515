import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = theme => ({
  slider: {
    position: "relative",
    padding: "0 30px",
    margin: "0 auto",
    maxWidth: 600,
    overflow: "hidden",
    borderRadius: 5
  },
  slidesWrapper: {
    position: "relative",
    minHeight: 100,
    "@media(max-width: 600px)": {
      minHeight: 200
    }
  },
  slide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "-100%",
    width: "100%",
    opacity: 0,
    transition: "all .3s ease",
    zIndex: -1,
    "&.active": {
      left: 0,
      opacity: 1,
      zIndex: 9
    },
    "@media(max-width: 600px)": {
      flexWrap: "wrap",
      "& img": {
        flex: "1 1 0"
      }
    }
  },
  image: {
    maxWidth: "70px",
    marginBottom: 15,
    marginTop: 15,
    display: "block"
  },
  textDetails: {
    marginLeft: 15,
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: "1.35417em",
    "& > p": {
      margin: "0 0 5px 0",
      fontSize: "1rem"
    },
    "& > small": {
      opacity: 0.7,
      "&:before": {
        content: "'\\2014   \\A0'"
      }
    },
    "@media(max-width: 600px)": {
      marginLeft: 0,
      marginTop: 15,
      "& > p": {
        fontSize: ".8rem"
      }
    }
  },
  navButton: {
    border: "none",
    background: "none",
    boxShadow: "none",
    outline: "none",
    cursor: "pointer",
    fontSize: "1.7rem",
    zIndex: 10,
    color: theme.palette.primary.contrastText,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%) scale(.9)",
    transition: "transform .2s ease",
    "&:hover": {
      transform: "translateY(-50%) scale(1)"
    },
    "&.prev": {
      left: -5
    },
    "&.next": {
      right: -5
    }
  }
});

class ClientsSlider extends Component {
  state = {
    interval: null,
    slide: 0,
    perSlide: 5,
    slides: [
      "https://lh3.googleusercontent.com/RGtygPGlqadY1sX0xzgdFK-5oBuxLnZBFLHONZLFYnSQaCAnniITl7yQlPnzxwpjMP0o",
      "https://lh3.googleusercontent.com/Py4hu7yM2_Peny23D_TYZCOb1oOHLPzj8wkC1rFeOm5YptwKoOePZg4_0j6pBoWHdQY",
      "https://lh3.googleusercontent.com/3o-cwzyA7yGkmkair2oHStHv2i8O4uU05I4Rsq8CGDT64aQEy62ejQeHI4OQZlptDw",
      "https://is1-ssl.mzstatic.com/image/thumb/Purple118/v4/f0/7f/70/f07f7037-39f5-ea6b-bb24-233981532f94/source/512x512bb.jpg",
      "https://lh3.googleusercontent.com/zSuhMe1pJR0zVo0pd5VheePilKOGpKI_zj62HjKH5LESZyOy3OmpHJY-DAMo8pmt8VY",
      "https://lh3.googleusercontent.com/S0oV0dK9FbPH0VgP5XL9Xq_1QdaxrZbQBQw8AIEE5BQZTat0tzBqSWujrmooTR9xgw",
      "https://lh3.googleusercontent.com/HSPAhW-0TsWLKNLGxzYODan6WxKr8jQ2XnP2DXFN9ASFjrEaK4nkbY_AQg5fZgF-JA",
      "https://is5-ssl.mzstatic.com/image/thumb/Purple128/v4/5e/4f/54/5e4f54f6-b219-17b0-ef69-fed7ef104ca1/source/512x512bb.jpg",
      "https://is1-ssl.mzstatic.com/image/thumb/Purple117/v4/68/db/3d/68db3d8f-c060-3667-1950-fb1f6be49625/source/512x512bb.jpg",
      "https://lh3.googleusercontent.com/tIndAF21m3nuymfppsX_kkxfpkAYdsXNbLPelameRdy-vkcmCQmStDTFt1QKbdBe7kc"
    ]
  };

  componentDidMount() {
    const slidesNumber = Math.ceil(
      this.state.slides.length / this.state.perSlide
    );
    const interval = setInterval(() => {
      this.setState(state => {
        const slide = state.slide + 1 >= slidesNumber ? 0 : state.slide + 1;
        return { slide };
      });
    }, 3000);
    this.setState({ interval });
  }

  componentWillUnmount() {
    this.clearAutoplay();
  }

  clearAutoplay = () => {
    clearInterval(this.state.interval);
    this.setState({ interval: null });
  };

  goPrev = () => {
    this.clearAutoplay();
    this.setState(state => {
      const slide = state.slide - 1 <= 0 ? 0 : state.slide - 1;
      return { slide };
    });
  };

  goNext = () => {
    this.clearAutoplay();
    const slidesNumber = Math.ceil(
      this.state.slides.length / this.state.perSlide
    );
    this.setState(state => {
      const slide =
        state.slide + 1 >= slidesNumber ? state.slide : state.slide + 1;
      return { slide };
    });
  };

  render() {
    const { classes } = this.props;
    const { slide, slides, perSlide } = this.state;
    const slidesNumber = Math.ceil(slides.length / perSlide);
    const arr = [];
    for (let i = 0; i < slidesNumber; i += 1) {
      const tmp = [...slides];
      arr.push({ id: i, arr: tmp.splice(i * perSlide, perSlide) });
    }

    return (
      <div className={classes.slider} onClick={this.clearAutoplay}>
        <button
          type="button"
          className={`${classes.navButton} prev`}
          onClick={this.goPrev}
        >
          <i className="far fa-angle-left" />
        </button>
        <div className={classes.slidesWrapper}>
          {arr.map((item, index) => (
            <div
              key={item.id}
              className={`${classes.slide} ${index === slide ? "active" : ""}`}
            >
              {item.arr.map(itemInner => (
                <img
                  src={itemInner}
                  key={itemInner}
                  alt="Client logo"
                  className={classes.image}
                />
              ))}
            </div>
          ))}
        </div>
        <button
          type="button"
          className={`${classes.navButton} next`}
          onClick={this.goNext}
        >
          <i className="far fa-angle-right" />
        </button>
      </div>
    );
  }
}

ClientsSlider.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ClientsSlider);

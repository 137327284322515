import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import FontAwesome from "react-fontawesome";
import SocialLogin from "react-provider-login";
import classNames from "classnames";
import ButtonBase from "@material-ui/core/ButtonBase";

const styles = () => ({
  googleBck: {
    background: "#dd4b39"
  },
  socialBtn: {
    textDecoration: "none",
    width: "50%",
    height: "100%",
    padding: "10px 10px",
    color: "#fff",
    marginTop: "20px",
    maxHeight: "60px",
    border: "0px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "0 0 2px 2px",
    transform: "rotateZ(0deg)",
    transition: "all 0.1s ease-out",
    borderBottomWidth: "7px"
  },
  githubBck: {
    background: "#2a2a2a"
  }
});

const GithubButton = ({ classes, triggerLogin, ...props }) => (
  <ButtonBase
    onClick={triggerLogin}
    focusRipple
    className={classNames(classes.githubBck, classes.socialBtn)}
    {...props}
  >
    {/* <FontAwesome name="github" size="lg" /> */}
    <i className="fab fa-github fa-lg" />
    &nbsp;Github
  </ButtonBase>
);

GithubButton.propTypes = {
  classes: PropTypes.object.isRequired,
  triggerLogin: PropTypes.func.isRequired
};

export default SocialLogin(withStyles(styles)(GithubButton));

import initialState from "Store/initialState";
import * as actionTypes from "./actionTypes";

export default function(state = initialState.notifications, action) {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION:
      return [...state, action.notification];
    case actionTypes.REMOVE_NOTIFICATION:
      return state.filter(item => item.id !== action.id);
    case actionTypes.REMOVE_ALL_NOTIFICATIONS:
      if (action.force) {
        return [];
      }
      return state.filter(item => !item.canDismiss);
    default:
      return state;
  }
}

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "Shared/Logo";
import NotificationsContainer from "Shared/Notification";
import {
  openAccountSettings,
  registerStudentLogin
} from "Store/modules/common/accountSettings/actions";
import {
  changeThemeColor,
  hideStudentMessage
} from "Store/modules/common/ui/actions";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ModalsContainer from "Shared/Modal";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import Services from "Shared/Services";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { logout } from "Store/modules/common/auth/actions";
import { push } from "connected-react-router";
import Hidden from "@material-ui/core/Hidden";
import Headway from "Shared/Services/Headway";
import { checkFreePlan } from "Utils/helpers";
import Statistics from "Routes/Dashboard/components/Statistics";
import ToolBarTools from "./ToolBarTools";
import NavigationList from "./NavigationList";
import ProfileBar from "./ProfileBar";
import FreeLock from "./FreeLock";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    height: 58
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: `${theme.spacing.unit * 11}px 45px ${theme.spacing.unit *
      3}px 50px`,
    overflowY: "scroll",
    "@media(max-width: 768px)": {
      padding: `${theme.spacing.unit * 3}px 20px ${theme.spacing.unit *
        3}px 20px`
    }
  },
  bannerOn: {
    padding: `${theme.spacing.unit * 18}px 45px ${theme.spacing.unit *
      3}px 50px`
  },
  logo: {
    display: "flex"
  },
  btnIntial: {
    height: "inherit"
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  locker: {
    width: "100%",
    height: "80vh"
  },
  headway: {
    width: 32,
    alignSelf: "center",
    "& #headway > span:not(.HW_visible)": {
      display: "none"
    }
  },
  regularToolbar: {
    minHeight: "58px",
    maxHeight: "65px",
    "@media (min-width: 75em)": {
      width: "calc(100% + 30px)",
      position: "relative",
      left: -15
    }
  },
  longText: {
    maxWidth: 600,
    margin: `20px auto ${theme.spacing.unit}px`,
    padding: `0 ${theme.spacing.unit * 4}px`
  }
});

class DefaultLayout extends React.PureComponent {
  state = {
    open: false
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      classes,
      theme,
      children,
      lockDashboard,
      showAccountSettings,
      logoutUser,
      changeRoute,
      currentAppId,
      currentPath,
      currentAppDevices,
      old_plan,
      freePlan,
      devices,
      trial
    } = this.props;

    const customLockDashboard =
      !lockDashboard && !old_plan && freePlan && devices >= 1000;

    const bannerShown = lockDashboard || (!old_plan && !freePlan && trial);

    let content;
    // Lock the whole dashboard if user is on free plan and exceeded devices limit
    if (customLockDashboard) {
      content = (
        <FreeLock
          user={this.props.user}
          changeThemeColor={this.props.changeThemeColor}
          showAccountSettings={showAccountSettings}
        />
      );
    } else {
      content = <div className="container">{children}</div>;
    }
    return (
      <div className={classes.root}>
        <AppBar position="absolute" className={classes.appBar}>
          <div className="container">
            <Toolbar
              classes={{ regular: classes.regularToolbar }}
              disableGutters={!this.state.open}
            >
              <div className={classes.logo}>
                <IconButton
                  className={classes.btnIntial}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
                {!this.state.open && (
                  <Hidden smDown>
                    <Logo push={changeRoute} />
                  </Hidden>
                )}
              </div>
              <ToolBarTools />
              <div className={classes.headway}>
                <Headway
                  selector="#headway"
                  account={process.env.HEADAWAY_ACCOUNT}
                />
              </div>
              <ProfileBar
                className={classes.topBar}
                logoutUser={logoutUser}
                openAccountSettings={showAccountSettings}
              />
            </Toolbar>
          </div>
          {bannerShown && <Statistics />}
        </AppBar>
        <Drawer open={this.state.open} onClose={this.handleDrawerClose}>
          <div className={classes.toolbar}>
            <div style={{ flex: 1 }}>
              <Logo color={theme.palette.primary.main} sidebar />
            </div>

            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <NavigationList
            openAccountSettings={showAccountSettings}
            logoutUser={logoutUser}
            currentAppId={currentAppId}
            currentPath={currentPath}
            currentAppDevices={currentAppDevices}
          />
        </Drawer>
        <main
          className={`${classes.content} ${
            bannerShown ? classes.bannerOn : ""
          }`}
        >
          {content}
        </main>
        <ModalsContainer />
        <Services />
        <NotificationsContainer />
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  lockDashboard: PropTypes.bool,
  showAccountSettings: PropTypes.func.isRequired,
  // openConfirmModal: PropTypes.func.isRequired,
  changeRoute: PropTypes.func.isRequired,
  changeThemeColor: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  currentAppId: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  currentAppDevices: PropTypes.number.isRequired,
  devices: PropTypes.number.isRequired,
  trial: PropTypes.bool.isRequired,
  freePlan: PropTypes.bool.isRequired,
  old_plan: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};

DefaultLayout.defaultProps = {
  lockDashboard: false
};

const mapStateToProps = state => ({
  user: state.auth.user,
  lockDashboard: state.auth.user.lock_dashboard,
  location: state.router.location,
  currentAppId: state.ui.currentAppId,
  currentPath: state.router.location.pathname,
  currentAppDevices: state.ui.appTotalCount || 0,
  devices: state.dashboard ? state.dashboard.devices : 0,
  old_plan: state.auth.old_plan,
  freePlan: checkFreePlan(state.auth.user.plan_id),
  trial: state.auth.user.trial_status,
  hideMessage: state.ui.hideStudentMessage,
  accountSettingsLock: state.accountSettings.lock,
  loginSource: state.auth.loginSource
});
const mapDispatchToProps = dispatch => ({
  showAccountSettings: paylaod => dispatch(openAccountSettings(paylaod)),
  // openConfirmModal: payload => dispatch(openModal(payload)),
  logoutUser: payload => dispatch(logout(payload)),
  changeRoute: payload => dispatch(push(payload)),
  changeThemeColor: payload => dispatch(changeThemeColor(payload)),
  registerStudentLogin: temp => dispatch(registerStudentLogin(temp)),
  hideStudentMessage: () => dispatch(hideStudentMessage())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DefaultLayout));

import HttpClient from "Utils/HttpClient";
import Api from "Api";
// import { push } from "connected-react-router";
import { createNotification as notify } from "Store/modules/common/notifications/actions";
import * as actionTypes from "./actionTypes";

export const setData = payload => ({
  type: actionTypes.SET_DATA,
  payload
});

export const createNotification = notify;

export const reset = payload => ({
  type: actionTypes.RESET,
  payload
});

const setLoading = payload => ({
  type: actionTypes.LOADING,
  payload
});

export const setBlock = payload => ({
  type: actionTypes.SET_BLOCK,
  payload
});

export const showTrialModal = payload => ({
  type: actionTypes.SHOW_TRIAL_MODAL,
  payload
});
export const setStep = payload => ({
  type: actionTypes.SET_STEP,
  payload
});

export const setCCData = payload => ({
  type: actionTypes.SET_CC_DATA,
  payload
});

export const setSurveyChoice = payload => {
  Intercom("trackEvent", "cancel-after-trial");
  Intercom("update", {
    whycancelled: payload,
    plan_id: "free",
    trial: "false"
  });
  return {
    type: actionTypes.SET_SURVEY_CHOICE,
    payload
  };
};

export const downgradeTofree = payload => dispatch => {
  HttpClient.post("/account/downgrade", {})
    .then(res => {
      console.log(res.data);
      dispatch(setData(res.data));
      dispatch(setStep("Done"));
      dispatch(setSurveyChoice(payload));
      // dispatch(push("/"));
    })
    .catch(err => {
      console.log(err);
    });
};

export const subscribeToPlan = (payload, type) => (dispatch, getState) => {
  dispatch(setBlock(true));
  const requestPayload = {
    payment_method_nonce: payload
  };
  if (type === "paypal") {
    requestPayload.email = payload.details.email;
    requestPayload.payment_method_nonce = payload.nonce;
  }
  HttpClient.post(Api.planSub(getState().trial.matchingPlan), requestPayload)
    .then(res => {
      if (res.data.success) {
        dispatch(setData(res.data));
        dispatch(setBlock(false));
        window.location.href = "/";
      } else {
        dispatch(setBlock(false));
        const message = Object.keys(res.data.errors)
          .map(error => res.data.errors[error])
          .join("\n");
        dispatch(
          createNotification({
            message,
            color: "danger",
            timeout: 3000
          })
        );
      }
      console.log(res.data);
    })
    .catch(err => {
      console.log(err);
      dispatch(setBlock(false));
      dispatch(
        createNotification({
          message: "Failed to process your info correctly, please try again.",
          color: "danger",
          timeout: 3000
        })
      );
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
};

export const setAmount = payload => ({
  type: actionTypes.SET_AMOUNT,
  payload
});

export const getData = () => dispatch => {
  dispatch(setLoading(true));
  HttpClient.get(Api.trialEndPoint)
    .then(res => {
      console.log(res.data);
      dispatch(setData(res.data));
      dispatch(setLoading(false));
    })
    .catch(error => {
      console.log(error);
    });
};

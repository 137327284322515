import m from "moment";
import { extendMoment } from "moment-range";
import iosDevices from "ios-device-list";
import * as phoneModel from "find-phone-model";
import isoCountries from "Utils/countries";
import isoLangs from "Utils/langs";
import { get, unionBy } from "lodash";
/* eslint-disable */
import md5 from "md5";
/* eslint-enable */
import compareVersions from "node-version-compare";
import config from "../config";

const moment = extendMoment(m);

export default {};

export const planPrices = {
  free: 0,
  n29: 29,
  n49: 49,
  n129: 129,
  n199: 199,
  "3gzg": 49,
  hcyw: 15,
  b3h6: 129,
  "8jdb": 129,
  pc62: 249,
  hj89: 499,
  qhpm: 30,
  djww: 41,
  gsmb: 52,
  "6kqr": 41,
  th96: 56,
  "8tf6": 70,
  mj42: 47,
  "2v82": 65,
  prsg: 82,
  demo: 0
};

export const planTitles = {
  free: "Free",
  n29: "Starter",
  n49: "Basic",
  n129: "Plus",
  n199: "Premium",
  enterprise: "Enterprise"
};
const WebPlatormCodes = [2, 3, 4, 5];
const WebPlatormLabels = ["web", "safari", "chrome", "firefox", "opera"];

export const checkFreePlan = plan_id => !(plan_id && plan_id !== "free");

export const checkPlan = (target, current) => {
  if (current && current.includes("n")) {
    const c = Number(current.replace("n", ""));
    const t = Number(target.replace("n", ""));
    if (c >= t) {
      return true;
    }
    return false;
  }
  return false;
};

export const stringifyLaravelErrors = errorsObj =>
  Object.keys(errorsObj).reduce(
    (accMsgKey, curMsgKey) => accMsgKey.concat(errorsObj[curMsgKey], ", "),
    ""
  );
export const copyString = (updateSnackFunc, str) => {
  const parentElement =
    document.activeElement.childElementCount > 0
      ? document.activeElement
      : document.body;
  const el = document.createElement("textarea");
  el.value = str;
  parentElement.appendChild(el);
  el.select();
  document.execCommand("copy");
  parentElement.removeChild(el);
  updateSnackFunc({
    message: "Copied!",
    color: "success",
    close: true,
    place: "bc",
    autoHideDuration: 2000
  });
};

/**
 *
 * @param {array} arrOfMonthlyActiveUsers - array of objects each
 * @param {*} startDate
 * @param {*} endDate
 */
export const formatActiveUsersByDay = (
  arrOfMonthlyActiveUsers = [],
  startDate,
  endDate
) => {
  const range = moment.range(startDate, endDate);
  const activeRange = [...range.by("days")].map(d => ({
    name: d.format("YYYY-MM-DD"),
    activeUsers: 0
  }));

  const active = arrOfMonthlyActiveUsers.map(dayStats => ({
    name: dayStats.m.d,
    activeUsers: dayStats.count.users
  }));

  return unionBy(active, activeRange, "name").sort((a, b) => {
    if (moment(a.name, "YYYY-MM-DD").isAfter(b.name)) {
      return 1;
    }
    if (moment(a.name, "YYYY-MM-DD").isBefore(b.name)) {
      return -1;
    }
    return 0;
  });
};
export const checkBothforinAppProd = app =>
  !get(app, ["production", "ios", "checked"]) &&
  get(app, ["devicesNum", "ios"]) >= 200 &&
  get(app, ["devicesNum", "android"]) >= 200 &&
  !get(app, ["production", "android", "checked"]);

export const checkAndroidforinAppProd = app =>
  !get(app, ["production", "android", "checked"]) &&
  get(app, ["devicesNum", "android"]) >= 200 &&
  (get(app, ["production", "ios", "checked"]) ||
    get(app, ["devicesNum", "ios"]) < 200);

export const checkiOSforinAppProd = app =>
  !get(app, ["production", "ios", "checked"]) &&
  get(app, ["devicesNum", "ios"]) >= 200 &&
  (get(app, ["production", "android", "checked"]) ||
    get(app, ["devicesNum", "android"]) < 200);

export const urlStoreValidation = (platform, url) => {
  if (platform === "android") {
    const androidExp = RegExp(
      "(https?://play.google.com/store/((apps)|(movies)|(books)|(newsstand))/details).+"
    );
    const matched = androidExp.test(url);
    return matched;
  }
  if (platform === "ios") {
    const iosExp = RegExp("(https?://itunes.apple.com/([a-zA-z]{2})/app).+");
    const matched = iosExp.test(url);
    return matched;
  }

  return false;
};

export const getMatchingPlan = (numOfDevices = 0) => {
  if (numOfDevices >= 0 && numOfDevices <= 50000) return "n49";
  if (numOfDevices > 50000 && numOfDevices <= 150000) return "n129";
  if (numOfDevices > 150000 && numOfDevices <= 300000) return "n199";
  return "enterprise";
};

export const getNested = (p, o) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

export const get_gravatar = (email, size = 80) => {
  const hash = md5(email.trim().toLowerCase());

  return `http://www.gravatar.com/avatar/${hash}.jpg?s=${size}`;
};

export const isEmpty = value =>
  value === null ||
  value === undefined ||
  value === "undefined" ||
  value.length === 0;

export const uniqueRandomColors = (numOfColors = 1) => {
  const colorsArr = [
    "#ef5350",
    "#EC407A",
    "#AB47BC",
    "#7E57C2",
    "#5C6BC0",
    "#42A5F5",
    "#26A69A",
    "#66BB6A",
    "#FFA726",
    "#FF7043",
    "#8D6E63",
    "#78909C"
  ];

  const resColors = [];

  for (let i = 0; i < numOfColors; ) {
    const genColor = colorsArr[Math.floor(Math.random() * colorsArr.length)];

    if (resColors.indexOf(genColor) === -1) {
      resColors.push(genColor);
      i += 1;
    }
  }

  return resColors;
};

// https://gist.github.com/primaryobjects/a4d0e4bb6094de458ba87fb0c3b002a2
// 0 if the versions are equal
// a negative integer iff v1 < v2
// a positive integer iff v1 > v2
// NaN if either version string is in the wrong format

export const versionCompare = (v1, v2, options) => {
  const lexicographical = options && options.lexicographical;
  const zeroExtend = options && options.zeroExtend;
  let v1parts = v1.split(".");
  let v2parts = v2.split(".");

  if (!v1parts || !v2parts) return 0;

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push("0");
    while (v2parts.length < v1parts.length) v2parts.push("0");
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (let i = 0; i < v1parts.length; i += 1) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] < v2parts[i]) {
      return -1;
    }
    if (v1parts[i] > v2parts[i]) {
      return 1;
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
};

export const calcLatestVerUsers = platformVersionsUserStats => {
  const raw_versions_titles = Object.keys(platformVersionsUserStats);
  const versions_floats = raw_versions_titles.map(x =>
    x.replace(/．/g, ".").trim()
  );

  return versions_floats.reduce(
    (latestVersion, curVersion, index) => {
      if (versionCompare(curVersion, latestVersion.versionTrimmed) > 0) {
        return {
          versionTrimmed: curVersion,
          versionUsers:
            platformVersionsUserStats[raw_versions_titles[index]].ncount,
          versionKey: raw_versions_titles[index]
        };
      }
      return latestVersion;
    },
    {
      versionTrimmed: "0.0",
      versionUsers: 0,
      versionKey: "0. 0. 0"
    }
  );
};

export const randomColor = () => {
  const colorsArr = [
    "#ef5350",
    "#EC407A",
    "#AB47BC",
    "#7E57C2",
    "#5C6BC0",
    "#42A5F5",
    "#26A69A",
    "#66BB6A",
    "#FFA726",
    "#FF7043",
    "#8D6E63",
    "#78909C"
  ];

  return colorsArr[Math.floor(Math.random() * colorsArr.length)];
};

/**
 * check if the platform is web by platform form code (2,3,4) or by label ("web","safari","firefox","chrome") if byLabel is true
 * @param {string | number} code
 * @param {boolean} byLabel
 */
export const isWebPlatform = (code, byLabel = false) =>
  byLabel ? WebPlatormLabels.includes(code) : WebPlatormCodes.includes(+code);

/**
 * check if app platforms has web platforms ("web","safari","firfox","chrome")
 * @param {array} platforms
 */
export const hasWebPlatform = platforms =>
  platforms.some(platform => isWebPlatform(platform, true));

/**
 * check if the app can add anther platform
 * @param {array} platforms
 */
export const canAddPlatform = platforms =>
  !hasWebPlatform(platforms) ||
  !(platforms.includes("android") && platforms.includes("ios"));

export const checkVersion = (feature, platforms, versions) => {
  const result = [];
  platforms.forEach(p => {
    if (versions[p]) {
      versions[p].every(v => {
        let met;
        if (Object.prototype.hasOwnProperty.call(config.sdkVersions, feature)) {
          if (
            Object.prototype.hasOwnProperty.call(config.sdkVersions[feature], p)
          ) {
            met = compareVersions(v, config.sdkVersions[feature][p]) >= 0;
          }
        }
        if (met) {
          result.push(p);
          return false;
        }
        return true;
      });
    }
  });
  return result.length > 0 ? result : false;
};

function langKeyByValue(obj, val) {
  const lang = Object.entries(obj).find(
    i => i[1].name.toLowerCase() === val.toLowerCase()
  );
  if (lang) {
    return lang[0];
  }
  return lang;
}

function langNameByKey(obj, val) {
  const lang = Object.entries(obj).find(
    i => i[0].toLowerCase() === val.slice(0, 2).toLowerCase()
  );
  if (lang) {
    return lang[1].name;
  }
  return "----";
}

export function searchLangByKey(key) {
  return langNameByKey(isoLangs, key);
}

export function searchLangByValue(name) {
  return langKeyByValue(isoLangs, name);
}

function objectKeyByValue(obj, val) {
  const country = Object.entries(obj).find(
    i => i[1].toLowerCase() === val.toLowerCase()
  );
  if (country) {
    return country[0];
  }
  return country;
}

export function searchCountryByValue(name) {
  return objectKeyByValue(isoCountries, name);
}

export function formatABTestingData(abTesting, pushNotifications) {
  return {
    selectedTest: "a",
    testType: abTesting.type,
    a: {
      ...pushNotifications[0]
    },
    b: {
      ...pushNotifications[1]
    },
    waiting_interval: abTesting.waiting_interval,
    send_diff: abTesting.send_diff,
    _id: abTesting._id,
    d: abTesting.d,
    winner: abTesting.winner
  };
}

function formatDevicelModel(device) {
  let model = "----";
  if (get(device, ["m", "d"])) {
    const deviceLower = device.m.d.toLowerCase();
    if (
      deviceLower.indexOf("iphone") > -1 ||
      deviceLower.indexOf("ipad") > -1
    ) {
      model = iosDevices.generationByIdentifier(String(device.m.d));
    } else {
      const arrOfDevices = phoneModel.findPhone(String(device.m.d));

      if (arrOfDevices.length > 0 && arrOfDevices[0]["Retail Branding"]) {
        model = `${arrOfDevices[0]["Retail Branding"]} ${
          arrOfDevices[0]["Marketing Name"]
        } `;
      } else if (
        arrOfDevices.length > 1 &&
        arrOfDevices[1]["Retail Branding"]
      ) {
        model = `${arrOfDevices[1]["Retail Branding"]} ${
          arrOfDevices[1]["Marketing Name"]
        } `;
      } else {
        model = get(device, ["m", "d"]) ? device.m.d : "----";
      }
    }
  } else {
    model = "----";
  }

  return model;
}

export function devicesListFormat(devicesList) {
  return devicesList
    .filter(device => !!device)
    .map(device => {
      const timestamp = device._id.toString().substring(0, 8);

      const date = new Date(parseInt(timestamp, 16) * 1000);
      const lastSeen =
        device.ls && device.ls.$date && device.ls.$date.$numberLong
          ? moment
              .utc(new Date(Number(device.ls.$date.$numberLong)))
              .from(moment.utc())
          : "";

      const events = [];
      if (device.e) {
        const arr = Object.keys(device.e);
        arr.sort(
          (a, b) =>
            new Date(Number(device.e[b].last_occur.$date.$numberLong)) -
            new Date(Number(device.e[a].last_occur.$date.$numberLong))
        );
        arr.forEach(key => {
          const item = device.e[key];
          const ls = moment(
            new Date(Number(item.last_occur.$date.$numberLong))
          ).format("Do MMM YYYY");
          events.push({
            text: `${key[0].toUpperCase()}${key.slice(1).replace("_", " ")}`,
            date: ls
          });
        });
      }

      const model = formatDevicelModel(device);

      return {
        id: device._id,
        token: device.token,
        registered: device._id ? moment.utc(date).from(moment.utc()) : " ",
        platform: device.platform,
        alias: device.alias ? device.alias : "----",
        tags: device.tags ? device.tags : [],
        badge: device.badge ? device.badge : "----",
        device: model,
        lang: get(device, ["m", "l"]) ? searchLangByKey(device.m.l) : "----",
        country: get(device, ["ccode"]) ? device.ccode : "----",
        city: get(device, ["city"]) ? device.city : "----",
        carrier: get(device, ["m", "c"]) ? device.m.c : "----",
        test: device.alias === "PB_TESTING_DEVICE",
        ls: lastSeen,
        name: device.name,
        f_name: device.f_name || "----",
        l_name: device.l_name || "----",
        email: device.email || "----",
        gender: device.gender || "----",
        phone: get(device, "phone") ? device.phone : "",
        avatar: get(device, "avatar") ? device.avatar : "",
        social_profiles: get(device, "social_profiles")
          ? device.social_profiles
          : "",
        companies: get(device, "companies") ? device.companies : "",
        events
        // age: device.age || "----"
      };
    });
}

export const computedTableColumns = tableColumns => {
  let editCommand;
  const cols = [
    ...tableColumns.reduce((accCol, c) => {
      if (
        c.type === "editComand" ||
        c.type.toString().indexOf("editCommand") > -1
      ) {
        editCommand = c;
        return accCol;
      }
      if (
        c.key === "data_platform" ||
        c.key.toString().indexOf("platform") > -1
      ) {
        accCol.push({
          ...c,
          column: {
            name: "platform",
            title: "Platform"
          },
          width: 90
        });
      } else if (
        c.key === "data_badge" ||
        c.key.toString().indexOf("badge") > -1
      ) {
        accCol.push({
          ...c,
          column: {
            name: "badge",
            title: "Badge"
          },
          width: 75
        });
      } else if (
        c.key === "data_country" ||
        c.key.toString().indexOf("country") > -1
      ) {
        accCol.push({
          ...c,
          column: {
            name: "country",
            title: "Country"
          },
          width: 120
        });
      } else if (
        c.key === "data_lang" ||
        c.key.toString().indexOf("lang") > -1
      ) {
        accCol.push({
          ...c,
          column: {
            name: "lang",
            title: "Lang"
          },
          width: 75
        });
      } else if (
        c.key === "data_carrier" ||
        c.key.toString().indexOf("carrier") > -1
      ) {
        accCol.push({
          ...c,
          column: {
            name: "carrier",
            title: "Carrier"
          },
          width: 90
        });
      } else if (
        c.key === "data_alias" ||
        c.key.toString().indexOf("alias") > -1
      ) {
        accCol.push({
          ...c,
          column: {
            name: "alias",
            title: "Alias"
          },
          width: 80
        });
      } else if (
        c.key === "data_email" ||
        c.key.toString().indexOf("email") > -1
      ) {
        accCol.push({
          ...c,
          column: {
            name: "email",
            title: "Email"
          },
          width: 220
        });
      } else if (
        c.key === "data_test" ||
        c.key.toString().indexOf("test") > -1
      ) {
        accCol.push({
          ...c,
          column: {
            name: "test",
            title: "Test"
          },
          width: 70
        });
      } else if (
        c.key === "data_push" ||
        c.key.toString().indexOf("push") > -1
      ) {
        accCol.push({
          ...c,
          column: {
            name: "push",
            title: "Push"
          },
          width: 70
        });
      } else {
        accCol.push(c);
      }
      return accCol;
    }, [])
  ];

  return [...cols, editCommand];
};

export const browsers = ["safari", "chrome", "opera", "firefox", "web"];
const platformsOrder = ["ios", "android", "web"];
const mobiles = ["ios", "android"];

export function getPlatformsKeys(platforms) {
  return Object.keys(platforms)
    .map(p => (browsers.includes(p) ? "web" : p))
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) =>
      platformsOrder.indexOf(a) > platformsOrder.indexOf(b) ? 1 : -1
    );
}

export function canCreateAbtesting(devices, limit) {
  let canCreate = false;
  // Delete total count
  let webCount = 0;
  Object.keys(devices)
    .filter(prefix => prefix !== "t")
    .forEach(key => {
      if (browsers.indexOf(key) > -1) {
        webCount += devices[key];
      } else if (devices[key] >= limit) {
        canCreate = true;
      }
    });

  if (!canCreate && webCount >= limit) {
    canCreate = true;
  }

  return canCreate;
}

export function formatDate(date) {
  const d = new Date(date);
  const year = d.getFullYear();
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
}

export const getAvailablePlatforms = devices =>
  Object.keys(devices)
    .map(item => {
      if (browsers.includes(item) && devices[item] > 0) {
        return "Web";
      }
      if (mobiles.includes(item) && devices[item] > 0) {
        return "Mobile";
      }
      return null;
    })
    .filter((value, index, self) => {
      if (!value) {
        return false;
      }
      return self.indexOf(value) === index;
    });

export function getCountryImage(ccode) {
  const list = {
    MY:
      "https://d2v9y0dukr6mq2.cloudfront.net/video/thumbnail/stoVFy8/kuala-lumpur-city-skyline-malaysia-4k-time-lapse_b3-meebs__F0000.png",
    IR: "https://iranunveiled.files.wordpress.com/2015/08/tehran-skyline-1.jpg",
    AF:
      "https://cdn.pushbots.com/countries/afghanistan-blue-mosque.adapt.945.1.jpg",
    AR:
      "https://cdn.pushbots.com/countries/argentina-street-dancing.adapt.945.1.jpg",
    AU:
      "https://content.paulreiffer.com/wp-content/uploads/2015/01/The-Morning-After-Sydney-Opera-House-Sunrise-Paul-Reiffer-Professional-Landscape-Photographer-Long-Exposure-Golden-Harbour-Australia.jpg",
    AZ:
      "https://cdn.pushbots.com/countries/azerbaijan-buildings.adapt.945.1.jpg",
    BE:
      "https://cdn.pushbots.com/countries/belgium-ghent-channel.adapt.945.1.jpg",
    BS: "https://cdn.pushbots.com/countries/bahamas-beach.adapt.945.1.jpg",
    BA:
      "https://cdn.pushbots.com/countries/bosnia-and-herzegovina-mostar-city.adapt.945.1.jpg",
    BR: "https://cdn.pushbots.com/countries/brazil-soccer.adapt.945.1.jpg",
    BW: "https://cdn.pushbots.com/countries/botswana-huts.adapt.945.1.jpg",
    CA:
      "https://cdn.pushbots.com/countries/canada-playing-hockey.adapt.945.1.jpg",
    CH:
      "https://cdn.pushbots.com/countries/switzerland-alphorns.adapt.945.1.jpg",
    CL: "https://cdn.pushbots.com/countries/chile-moai-statues.adapt.945.1.jpg",
    CN: "https://cdn.pushbots.com/countries/china-dragon.adapt.945.1.jpg",
    CM: "https://cdn.pushbots.com/countries/Cameroon-gorilla.adapt.945.1.jpg",
    CO: "https://cdn.pushbots.com/countries/colombia-dancing.adapt.945.1.jpg",
    CR:
      "https://cdn.pushbots.com/countries/costa-rica-tree-frog.adapt.945.1.jpg",
    CU: "https://cdn.pushbots.com/countries/cuba-matanzas.adapt.945.1.jpg",
    DE:
      "https://cdn.pushbots.com/countries/germany-munich-city-scene.adapt.945.1.jpg",
    DK:
      "https://cdn.pushbots.com/countries/denmark-copenhagen-canal.adapt.945.1.jpg",
    EC: "https://cdn.pushbots.com/countries/ecuador-carnival.adapt.945.1.jpg",
    EG:
      "https://i2.wp.com/earthnworld.com/wp-content/uploads/2015/12/Ancient-Egyptian-Pyramids-1.jpg?w=1280",
    ES: "https://cdn.pushbots.com/countries/spain-madrid.adapt.945.1.jpg",
    FR:
      "https://cdn.pushbots.com/countries/france-eiffel-tower.adapt.945.1.jpg",
    GB:
      "http://www.backdrops.co.uk/wp-content/gallery/landscape-photography/parliament-big-ben.jpg",
    GH: "https://cdn.pushbots.com/countries/ghana-beach-boats.adapt.945.1.jpg",
    GR: "https://cdn.pushbots.com/countries/greece-ruins-night.adapt.945.1.jpg",
    GT: "https://cdn.pushbots.com/countries/guatemala-volcano.adapt.945.1.jpg",
    HN:
      "https://cdn.pushbots.com/countries/honduras-ocean-port.adapt.945.1.jpg",
    HR: "https://cdn.pushbots.com/countries/Croatia-seaside.adapt.945.1.jpg",
    HT:
      "https://cdn.pushbots.com/countries/haiti-girls-running.adapt.945.1.jpg",
    IN: "https://cdn.pushbots.com/countries/india-tajmahal.adapt.945.1.jpg",
    IE: "https://cdn.pushbots.com/countries/ireland-dublin.adapt.945.1.jpg",
    IQ: "https://cdn.pushbots.com/countries/iraq-children.adapt.945.1.jpg",
    IS: "https://cdn.pushbots.com/countries/iceland-glacier.adapt.945.1.jpg",
    IL:
      "https://cdn.pushbots.com/countries/israel-man-dead-sea.adapt.945.1.jpg",
    IT: "https://cdn.pushbots.com/countries/italy-coliseum.adapt.945.1.jpg",
    JM: "https://cdn.pushbots.com/countries/jamaica-beach.adapt.945.1.jpg",
    JP: "https://cdn.pushbots.com/countries/japan-gardens.adapt.945.1.jpg",
    KE: "https://cdn.pushbots.com/countries/kenya-reserve.adapt.945.1.jpg",
    MA:
      "https://cdn.pushbots.com/countries/morrocco-marrakech-market.adapt.945.1.jpg",
    MX: "https://cdn.pushbots.com/countries/mexico-dancers.adapt.945.1.jpg",
    MN: "https://cdn.pushbots.com/countries/mongolia-camel.adapt.945.1.jpg",
    NG: "https://cdn.pushbots.com/countries/nigeria-festival.adapt.945.1.jpg",
    NI:
      "https://cdn.pushbots.com/countries/nicaragua-cathedral.adapt.945.1.jpg",
    NL:
      "https://cdn.pushbots.com/countries/netherlands-tulip-fields.adapt.945.1.jpg",
    NO: "https://cdn.pushbots.com/countries/norway-fjord.adapt.945.1.jpg",
    NP: "https://cdn.pushbots.com/countries/nepal-himalayas.adapt.945.1.jpg",
    NZ: "https://cdn.pushbots.com/countries/new-zealand-sheep.adapt.945.1.jpg",
    PA: "https://cdn.pushbots.com/countries/panama-canal.adapt.945.1.jpg",
    PE: "https://cdn.pushbots.com/countries/peru-machu-picchu.adapt.945.1.jpg",
    PH: "https://cdn.pushbots.com/countries/philippines-island.adapt.945.1.jpg",
    PL: "https://cdn.pushbots.com/countries/poland-warsaw.adapt.945.1.jpg",
    PT: "https://cdn.pushbots.com/countries/portugal-island.adapt.945.1.jpg",
    SV: "https://cdn.pushbots.com/countries/el-salvador-oxen.adapt.945.1.jpg",
    SE:
      "https://cdn.pushbots.com/countries/sweden-northern-lights.adapt.945.1.jpg",
    TH:
      "https://cdn.pushbots.com/countries/thailand-floating-market.adapt.945.1.jpg",
    TR: "https://cdn.pushbots.com/countries/turkey-istanbul.adapt.945.1.jpg",
    UY:
      "https://cdn.pushbots.com/countries/uruguay-carnival-drummers.adapt.945.1.jpg",
    US:
      "https://thumbnails.trvl-media.com/mBcVMXvwyOcicyPN9KBpLxdetbc=/cdn.lemediavault.com/images/bd5691ee7ce3dec147b9d7006aa27620.jpeg",
    ZA:
      "https://cdn.pushbots.com/countries/south-africa-johannesburg.adapt.945.1.jpg"
  };
  if (!Object.prototype.hasOwnProperty.call(list, ccode)) {
    return "";
  }
  return list[ccode];
}

export const reverseFiltersTransform = filters =>
  filters.map(query => {
    const q = { ...query };
    if (query.operatorInfo && query.operatorInfo.reverseValueTransformer) {
      q.value = q.operatorInfo.reverseValueTransformer(q.value);
    }
    return q;
  });

export const getSearchFilters = (query, reverse = false) => {
  if (!query) return "";
  const queryMap = {};

  // accumalate field's values and operators
  let queryString = `condition=${query.condition}`;
  query.query.forEach(queryItem => {
    if (!queryMap[queryItem.field]) {
      queryMap[queryItem.field] = {
        values: [],
        operators: [],
        operatorsIndex: []
      };
    }
    queryMap[queryItem.field].values.push(
      reverse && queryItem.operatorInfo.reverseValueTransformer
        ? queryItem.operatorInfo.reverseValueTransformer(queryItem.value)
        : queryItem.value
    );
    queryMap[queryItem.field].operators.push(queryItem.operator);
    queryMap[queryItem.field].operatorsIndex.push(queryItem.operatorIndex);
  });

  // build up the query string
  Object.keys(queryMap).forEach(key => {
    queryString += `&${key}=${queryMap[key].values.join(
      ","
    )}&${key}operator=${queryMap[key].operators.join(
      ","
    )}&${key}operatorIndex=${queryMap[key].operatorsIndex.join(",")}`;
  });

  return queryString;
};

export const isRTL = s => {
  const ltrChars =
    "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF";
  const rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC";
  const rtlDirCheck = new RegExp(`^[^${ltrChars}]*[${rtlChars}]`);

  return rtlDirCheck.test(s);
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "decimal"
});

export const formatNumber = num => formatter.format(num);

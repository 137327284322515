import React, { Component } from "react";
import PropTypes from "prop-types";
import Loadable from "react-loadable";
import ConfirmDailog from "Shared/ConfirmDialog";
import DialogPlaceholder from "Shared/DialogPlaceholder";

class Modal extends Component {
  shouldComponentUpdate() {
    return false;
  }

  onClose() {
    if (this.props.item.onClose) {
      this.props.item.onClose();
      this.props.onClose(this.props.item);
    } else {
      this.props.onClose(this.props.item);
    }
  }

  onConfirm() {
    if (this.props.item.onConfirm) {
      this.props.item.onConfirm();
    }
    this.props.onClose(this.props.item);
  }

  render() {
    const { type } = this.props.item;
    if (type === "confirmation") {
      return (
        <ConfirmDailog
          {...this.props.item}
          open
          onCancel={() => this.onClose(this.props.item)}
          onConfirm={() => this.onConfirm(this.props.item)}
        />
      );
    }
    if (type === "async") {
      const CustomModal = Loadable({
        loader: this.props.item.loader,
        loading: DialogPlaceholder
      });
      return (
        <CustomModal
          {...this.props.item}
          open
          onClose={() => this.onClose(this.props.item)}
          onConfirm={() => this.onConfirm(this.props.item)}
        />
      );
    }
    return null;
  }
}
Modal.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.oneOf(["confirmation", "async"]),
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    loader: PropTypes.func,
    props: PropTypes.shape({})
  }).isRequired,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};
Modal.defaultProps = {
  onConfirm: null,
  onClose: null
};
export default Modal;

export default {
  baseURL: "/api",
  auth: {
    login: "/auth/login",
    logout: "/auth/logout",
    register: "/auth/register",
    refresh: "/auth/refresh",
    email: "/auth/forget-password",
    me: "/auth/me",
    socialLogin: (socialProvider) => `/auth/handle/${socialProvider}`,
    reset: "/auth/reset-password",
  },
  pushEndPoint: "/application/campaign/",
  presetsEndPoint: "/application/preset/",
  dashboardEndPoint: "/",
  trialEndPoint: "/account/trial",
  checkSdkVersion: (appId) => `application/version/${appId}`,
  chartNotAnalytics: ({
    analyticsId,
    platform,
    startDate,
    endDate,
    chartType = "daily",
  }) =>
    `/application/notificationanalytics/${analyticsId}?platform=${platform}&start=${startDate}&end=${endDate}&type=${chartType}`,
  deviceStats: (appId, platform) =>
    `/application/deviceStats/${appId}/${platform}`,
  loadingNotification: (notificationId) =>
    `/application/notification/${notificationId}`,
  getChistoryList: (appId, pageNum, rowsNum) =>
    `/application/chistory/${appId}/?page=${pageNum}&rows=${rowsNum}`,
  getAPIhistoryList: (appId, pageNum, rowsNum) =>
    `/application/apihistory/${appId}/?page=${pageNum}&rows=${rowsNum}`,
  getOnehistoryList: (appId, pageNum, rowsNum) =>
    `/application/onehistory/${appId}/?page=${pageNum}&rows=${rowsNum}`,
  getPollhistoryList: (appId, pageNum, rowsNum) =>
    `/application/pollhistory/${appId}/?page=${pageNum}&rows=${rowsNum}`,
  updateDevice: (deviceId) => `/application/device/update/${deviceId}`,
  toggleTestDevice: (appId) => `/application/devices/${appId}/testdevice`,
  deleteDevice: (appId) => `/application/devices/${appId}/delete`,
  paginateDevices: (paginateObj) =>
    `/application/devices/${paginateObj.appId}/paginate?take=${
      paginateObj.pageSize
    }&skip=${paginateObj.pageSize * paginateObj.currentPage}${
      paginateObj.filter
    }&orderby=${paginateObj.sortColumnName}&direction=${
      paginateObj.direction
    }#ajax=${Math.random() * 100000}`,
  getTargetingData: (appId) => `/application/targeting/${appId}`,
  getReach: (appId, query) => `/application/reach/${appId}?${query}`,
  profileUpdate: `/account/update`,
  addCreditCard: "/account/billing/cc/add",
  listCards: "/account/billing",
  deleteCard: (cardId) => `/account/billing/cc/del/${cardId}`,
  defaultCard: (cardId) => `/account/billing/cc/default/${cardId}`,
  getInvoices: (page) => `/account/invoices?page=${page}`,
  changeProfilePic: (userId) => `/account/picture/${userId}`,
  removePic: (userId) => `/account/picture/${userId}/delete`,
  planSub: (id) => `/account/billing/subscribe/${id}`,
  getUserAccount: `/account`,
  unsubPlan: `/account/unsubscribe`,
  getActiveUsers: (startDate, endDate) =>
    `/application/active-users?start-date=${startDate}&end-date=${endDate}`,
  prodApps: (appId) => `/application/prod-apps/${appId}`,
  activities: "/get_activties",
  destroyApp: "/application/destroy",
  editData: (appId) => `/application/edit/${appId}?json=yes`,
  deleteApp: "application/destroy",
  addColl: (appId) => `application/collabrators/${appId}`,
  collPermission: (appId) => `application/collabrators/${appId}/permissions`,
  delColl: (appId, col_userid) =>
    `application/collabrators/${appId}/delete/${col_userid}`,
  unlinkTwitter: (appId) => `application/unlink/twitter/${appId}`,
  appTotalAnalytics: (appId, startDate, endDate, platform) =>
    `application/analytics/${appId}/?start=${startDate}&end=${endDate}&platform=${platform}`,
  loadingAnalytics: (appId) => `application/stats/${appId}`,
  getApp: (appId) => `get_app/${appId}`,
  newApp: "new_app",
  fetchSearchAppsByTitle: (title) => `/application/search?title=${title}`,
  fetchInvoiceById: (id) => `/account/invoice/print/${id}`,
  delNotification: (appId, notId) =>
    `/application/notification/del/${appId}/${notId}`,
};

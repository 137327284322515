import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    width: "100vw",
    height: "100vh",
    background: "white"
  }
});
export default withStyles(styles)(({ classes }) => (
  <div className={classes.wrapper}>
    <div className="spinner">
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
  </div>
));

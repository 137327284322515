import React from "react";
import { withStyles } from "@material-ui/core/styles";
// import { get } from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const styles = () => ({
  overlay: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    opacity: 1,
    willChange: "opacity",
    transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    display: "flex",
    alignItems: "center",
    zIndex: 10
  },
  image: {
    display: "block",
    margin: "auto"
  }
});

const DialogPlaceholder = ({ classes }) => (
  <div className={classes.overlay} open>
    <div className="spinner">
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
    {/* <img
      alt="Loading.."
      tag="div"
      className={`show-loading-animation ${classes.image}`}
      src={`/assets/svg/${get(user, "loading.spinnerName", "oval")
        .split(" ")
        .join("")}.svg`}
    /> */}
  </div>
);

DialogPlaceholder.propTypes = {
  classes: PropTypes.object.isRequired
  // user: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(withStyles(styles)(DialogPlaceholder));

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { removeNotification } from "Store/modules/common/notifications/actions";
import Notification from "./Notification";

class NotificationsContainer extends React.Component {
  componentWillUnmount() {
    if (this.defaultNode) {
      document.body.removeChild(this.defaultNode);
    }
    this.defaultNode = null;
  }

  handleRequestClose = id => (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.close(id);
  };

  _render() {
    const { notifications } = this.props;

    return (
      <div style={{ zIndex: 1400 }}>
        {notifications.map(notification => (
          <Notification
            key={notification.id}
            close={notification.close}
            message={notification.message}
            color={notification.color}
            handleClose={this.handleRequestClose(notification.id)}
            icon={notification.icon}
            place={notification.place}
            autoHideDuration={notification.autoHideDuration}
            open
          />
        ))}
      </div>
    );
  }

  render() {
    if (!this.defaultNode) {
      this.defaultNode = document.createElement("div");
      document.body.appendChild(this.defaultNode);
    }

    return createPortal(this._render(), this.defaultNode);
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications
});
const mapDispatchToProps = dispatch => ({
  close: payload => dispatch(removeNotification(payload))
});

NotificationsContainer.propTypes = {
  notifications: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer);

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { closeModal } from "Store/modules/common/modal/actions";
import Modal from "./Modal";

const ModalsContainer = ({ modals, close }) =>
  modals.map(modal => (
    <Modal item={modal} key={modal.id} onClose={modalObj => close(modalObj)} />
  ));

const mapStateToProps = state => ({
  modals: state.modal.modals
});
const mapDispatchToProps = dispatch => ({
  close: payload => dispatch(closeModal(payload))
});

ModalsContainer.propTypes = {
  modals: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalsContainer);

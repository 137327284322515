import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { indigo } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  lockedImg: {
    maxWidth: "500px",
    display: "block",
    margin: "0 auto"
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    width: "60%",
    "@media(max-width: 768px)": {
      width: "100%"
    }
  },
  lockedButton: {
    marginTop: theme.spacing.unit * 3
  },
  title: {
    marginBottom: theme.spacing.unit
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 2
  },
  body: {
    lineHeight: "1.8"
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    marginTop: -5
  }
});

const FreeLock = props => {
  const { classes } = props;
  useEffect(() => {
    props.changeThemeColor(indigo);
  });
  return (
    <div className={`container ${classes.container}`}>
      <Grid container alignItems="center" spacing={24}>
        <Grid item xs={12} lg={6}>
          <div className={classes.lockedText}>
            <Typography className={classes.title} variant="h4">
              Congratulations {props.user.name.split(" ")[0]}!
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              gutterBottom
            >
              You've now more than 1,000 subscribers!
            </Typography>
            <Typography className={classes.body} variant="body2" gutterBottom>
              However, this is the maximum free allowance on the free tier
              therefore if you'd like to continue sending unlimited push
              notifications to your users click on the button below!
            </Typography>
            <Button
              className={classes.lockedButton}
              variant="text"
              color="primary"
              onClick={() => props.showAccountSettings("Upgrade")}
            >
              <i className={`fas fa-crown ${classes.buttonIcon}`} />
              Upgrade to pro
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <img
            className={classes.lockedImg}
            src="images/locked.png"
            alt="Locked"
          />
        </Grid>
      </Grid>
    </div>
  );
};

FreeLock.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  changeThemeColor: PropTypes.func.isRequired,
  showAccountSettings: PropTypes.func.isRequired
};

export default withStyles(styles)(FreeLock);

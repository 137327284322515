export const devicesTableColumns = [
  // Customer Profile Fields
  { name: "name", title: "Name" },
  { name: "f_name", title: "First Name" },
  { name: "l_name", title: "Last Name" },
  { name: "email", title: "Email" },
  { name: "gender", title: "Gender" },
  // { name: "age", title: "Age" },

  { name: "token", title: "Token" },
  { name: "registered", title: "Signed up" },
  { name: "ls", title: "Last seen" },
  { name: "platform", title: "Platform" },
  { name: "alias", title: "Alias" },
  { name: "tags", title: "Tags" },
  { name: "badge", title: "Badge" },
  // { name: "device", title: "Device" },
  { name: "lang", title: "Lang" },
  { name: "country", title: "Country" },
  // { name: "carrier", title: "Carrier" },
  { name: "test", title: "Test Device" },
  { name: "push", title: "Push" }
];

export const initialFilteredDevicesTableColumns = [
  "badge",
  "tags",
  "alias",
  "gender",
  "f_name",
  "l_name",
  // 'age',
  "token"
];

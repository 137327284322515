import HttpClient from "Utils/HttpClient";
import Api from "Api";
import * as actionTypes from "./actionTypes";

export const setTopBarContent = payload => ({
  type: actionTypes.TOP_BAR_COMPONENT,
  payload
});

export const changeThemeColor = payload => ({
  type: actionTypes.CHANGE_THEME_COLOR,
  payload
});

export const setThemeManuallyChanged = payload => ({
  type: actionTypes.SET_THEME_MANUALLY_CHANGED,
  payload
});

export const changeAppDetails = payload => ({
  type: actionTypes.CHANGE_CURRENT_APP_DETAILS,
  payload
});

export const hideStudentMessage = payload => ({
  type: actionTypes.HIDE_STUDENT_MESSAGE,
  payload
});

export const setUpgradeBoxVisible = payload => ({
  type: actionTypes.SET_UPGRADE_BOX_VISIBLE,
  payload
});

export const setUpgradeBoxContent = payload => ({
  type: actionTypes.SET_UPGRADE_BOX_CONTENT,
  payload
});

export const fetchAppDetails = appid => (dispatch, getState) => {
  if (getState().ui.currentAppId !== appid) {
    HttpClient.get(Api.getApp(appid))
      .then(res => {
        const { data } = res.data;
        dispatch(
          changeAppDetails({
            appId: data.appID,
            title: data.appName,
            count: data.totalCount
          })
        );
      })
      .catch(err => {
        console.log(err);
      });
  }
};

export const forceFetchAppDetails = appid => dispatch =>
  new Promise(resolve => {
    HttpClient.get(Api.getApp(appid))
      .then(res => {
        const { data } = res.data;
        dispatch(
          changeAppDetails({
            appId: data.appID,
            title: data.appName,
            count: data.totalCount
          })
        );
        resolve(data.appName);
      })
      .catch(err => {
        console.log(err);
      });
  });

export const changeDevicesTableColumns = columns => ({
  type: actionTypes.CHANGE_DEVICES_LIST_COLUMNS,
  payload: columns
});

export const changeDevicesInitialFilters = filters => ({
  type: actionTypes.CHANGE_DEVICES_INITIAL_FILTERS,
  payload: filters
});

import React from "react";
import Grid from "@material-ui/core/Grid";
import { RectShape } from "react-placeholder/lib/placeholders";

const PlaceHolder = () => (
  <Grid container spacing={16} className="show-loading-animation">
    <Grid item xs={12}>
      <RectShape color="lightgray" style={{ width: "100%", height: "300px" }} />
    </Grid>
  </Grid>
);

export default PlaceHolder;

import React from "react";
import { Switch, Redirect } from "react-router-dom";
import Route from "Shared/PageRoute";
import PropTypes from "prop-types";
import notificationRoute from "Routes/Notification";
import userbaseRoute from "Routes/UserBase";
import devicesListRoute from "Routes/DevicesList";
import composerRoute from "Routes/Composer";
import dashboardRoute from "Routes/Dashboard";
import historyRoute from "Routes/History";
import appSettingsRoute from "Routes/AppSettings";
import appAnalyticsRoute from "Routes/AppAnalytics";
import trialRoute from "Routes/Trial";
import pollAnalyticsRoute from "Routes/PollAnalytics";
import { indigo, blueGrey, cyan, teal } from "@material-ui/core/colors";
import {
  changeThemeColor,
  fetchAppDetails,
  forceFetchAppDetails,
  setThemeManuallyChanged
  // changeDevicesInitialFilters
} from "Store/modules/common/ui/actions";
import Loadable from "react-loadable";
import GlobalPreloader from "Shared/GlobalPreloader";
import ErrorBoundry from "Shared/Errors/ErrorBoundry";
import DefaultLayout from "./DefaultLayout";

export default store => {
  const DashboardRoutes = ({ match }) => (
    <DefaultLayout>
      <ErrorBoundry>
        <Switch>
          <Route
            exact
            title="Trial is expired"
            path={`${match.url}trial`}
            render={props => {
              const Trial = trialRoute(store);
              return <Trial {...props} />;
            }}
          />
          <Route
            exact
            path={`${match.url}application/stats/:appId`}
            title="Application Analytics"
            onLoad={props => {
              if (
                store.getState().ui.currentAppId !== props.match.params.appId
              ) {
                store.dispatch(fetchAppDetails(props.match.params.appId));
              }
            }}
            render={props => {
              const AppAnalytics = appAnalyticsRoute(store);
              return <AppAnalytics {...props} />;
            }}
          />
          <Route
            exact
            title={
              store.getState().ui.currentAppTitle
                ? `Notification Analytics | ${
                    store.getState().ui.currentAppTitle
                  }`
                : "Notification Analytics"
            }
            path={`${match.url}application/notification/:notid`}
            onLoad={() => {
              store.dispatch(changeThemeColor(cyan));
            }}
            render={props => {
              const Notification = notificationRoute(store);
              const UserBase = userbaseRoute(store);
              return (
                <Notification
                  notificationType="basic"
                  UserBaseComp={UserBase}
                  {...props}
                />
              );
            }}
          />
          <Route
            exact
            title={
              store.getState().ui.currentAppTitle
                ? `Notification Analytics | ${
                    store.getState().ui.currentAppTitle
                  }`
                : "Notification Analytics"
            }
            path={`${match.url}application/abnotification/:notid`}
            onLoad={() => {
              store.dispatch(changeThemeColor(cyan));
            }}
            render={props => {
              const Notification = notificationRoute(store);
              const UserBase = userbaseRoute(store);
              return (
                <Notification
                  notificationType="abtesting"
                  UserBaseComp={UserBase}
                  {...props}
                />
              );
            }}
          />
          <Route
            exact
            title={
              store.getState().ui.currentAppTitle
                ? `Notification Analytics | ${
                    store.getState().ui.currentAppTitle
                  }`
                : "Notification Analytics"
            }
            path={`${match.url}application/inapp/:notid`}
            onLoad={() => {
              store.dispatch(changeThemeColor(cyan));
            }}
            render={props => {
              const Notification = notificationRoute(store);
              const UserBase = userbaseRoute(store);
              return (
                <Notification
                  notificationType="inApp"
                  UserBaseComp={UserBase}
                  {...props}
                />
              );
            }}
          />
          <Route
            exact
            title={
              store.getState().ui.currentAppTitle
                ? `Push | ${store.getState().ui.currentAppTitle}`
                : "Push"
            }
            path={`${match.url}application/campaign/:appId`}
            beforeEnter={props => {
              store.dispatch(changeThemeColor(blueGrey));
              store
                .dispatch(forceFetchAppDetails(props.match.params.appId))
                .then(appTitle => {
                  props.useTitle(`Push | ${appTitle}`);
                });
            }}
            // onLoad={props => {
            //   store.dispatch(changeThemeColor(blueGrey));
            //   if (
            //     store.getState().ui.currentAppId !== props.match.params.appId
            //   ) {
            //     store.dispatch(fetchAppDetails(props.match.params.appId));
            //   }
            // }}
            render={props => {
              const Composer = composerRoute(store);
              return <Composer {...props} />;
            }}
          />
          <Route
            exact
            title={
              store.getState().ui.currentAppTitle
                ? `Push | ${store.getState().ui.currentAppTitle}`
                : "Push"
            }
            path={`${match.url}application/push/:appId`}
            render={props => (
              <Redirect
                to={`${match.url}application/campaign/${
                  props.match.params.appId
                }`}
              />
            )}
          />
          <Route
            exact
            title={
              store.getState().ui.currentAppTitle
                ? `Application Settings | ${
                    store.getState().ui.currentAppTitle
                  }`
                : "Application Settings"
            }
            path={`${match.url}application/edit/:appId`}
            beforeEnter={props => {
              store.dispatch(changeThemeColor(indigo));
              store
                .dispatch(forceFetchAppDetails(props.match.params.appId))
                .then(appTitle => {
                  props.useTitle(`Application Settings | ${appTitle}`);
                });
            }}
            // onLoad={props => {
            //   store.dispatch(changeThemeColor(indigo));
            //   if (
            //     store.getState().ui.currentAppId !== props.match.params.appId
            //   ) {
            //     store.dispatch(fetchAppDetails(props.match.params.appId));
            //   }
            // }}
            render={props => {
              const AppSettings = appSettingsRoute(store);
              return <AppSettings {...props} />;
            }}
          />
          <Route
            exact
            title={
              store.getState().ui.currentAppTitle
                ? `Userbase | ${store.getState().ui.currentAppTitle}`
                : "Userbase"
            }
            path={`${match.url}application/show/:appId`}
            beforeEnter={props => {
              store
                .dispatch(forceFetchAppDetails(props.match.params.appId))
                .then(appTitle => {
                  props.useTitle(`Userbase | ${appTitle}`);
                });
            }}
            // onLoad={props => {
            //   store.dispatch(changeThemeColor(indigo));
            //   // if (
            //   //   store.getState().ui.currentAppId !== props.match.params.appId
            //   // ) {
            //     store.dispatch(forceFetchAppDetails(props.match.params.appId));
            //   // }
            // }}
            render={props => {
              const UserBase = userbaseRoute(store);
              return <UserBase {...props} />;
            }}
          />
          <Route
            exact
            title={
              store.getState().ui.currentAppTitle
                ? `Devices | ${store.getState().ui.currentAppTitle}`
                : "Devices"
            }
            path={`${match.url}application/devices/:appId`}
            beforeEnter={props => {
              if (
                store.getState().ui.currentAppId !== props.match.params.appId
              ) {
                store
                  .dispatch(forceFetchAppDetails(props.match.params.appId))
                  .then(appTitle => {
                    props.useTitle(`Devices | ${appTitle}`);
                  });
              }
            }}
            // onLoad={props => {
            //   store.dispatch(changeThemeColor(brown));
            //   // if (
            //   //   store.getState().ui.currentAppId !== props.match.params.appId
            //   // ) {
            //   store.dispatch(forceFetchAppDetails(props.match.params.appId));
            //   // }
            // }}
            render={props => {
              const Devices = devicesListRoute(store);
              return <Devices {...props} />;
            }}
          />
          <Route
            exact
            title={
              store.getState().ui.currentAppTitle
                ? `Delivery | ${store.getState().ui.currentAppTitle}`
                : "Delivery"
            }
            path={`${match.url}application/chistory/:appId`}
            beforeEnter={props => {
              store
                .dispatch(forceFetchAppDetails(props.match.params.appId))
                .then(appTitle => {
                  props.useTitle(`Delivery | ${appTitle}`);
                });
            }}
            // onLoad={props => {
            //   store.dispatch(changeThemeColor(cyan));
            //   if (
            //     store.getState().ui.currentAppId !== props.match.params.appId
            //   ) {
            //     store.dispatch(fetchAppDetails(props.match.params.appId));
            //   }
            // }}
            render={props => {
              const History = historyRoute(store);
              return <History {...props} />;
            }}
          />
          <Route
            exact
            title="Poll Analytics"
            path={`${match.url}application/poll/:pollId`}
            render={props => {
              store.dispatch(changeThemeColor(cyan));
              // showAppNavigation(store);
              const PollAnalytics = pollAnalyticsRoute(store);
              return <PollAnalytics {...props} />;
            }}
          />
          <Route
            exact
            path={`${match.url}`}
            title="Dashboard"
            onLoad={() => {
              const { themeManuallyChanged } = store.getState().ui;
              if (themeManuallyChanged) {
                store.dispatch(setThemeManuallyChanged(false));
                return;
              }
              const { plan_id } = store.getState().auth.user;
              store.dispatch(
                changeThemeColor(plan_id === "free" ? teal : cyan)
              );
            }}
            render={props => {
              const Dashboard = dashboardRoute(store);
              return <Dashboard {...props} />;
            }}
          />
          <Route
            component={Loadable({
              loader: () => import("Shared/Errors/404"),
              loading: GlobalPreloader
            })}
          />
        </Switch>
      </ErrorBoundry>
    </DefaultLayout>
  );

  DashboardRoutes.propTypes = {
    match: PropTypes.shape({
      url: PropTypes.string,
      params: PropTypes.object
    }).isRequired,
    useTitle: PropTypes.func
  };

  DashboardRoutes.defaultProps = {
    useTitle: () => {}
  };

  return DashboardRoutes;
};

import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "Routes/Auth/Login";
import Register from "Routes/Auth/Register";
import Reset from "Routes/Auth/Reset";
import GitHubSuccess from "Routes/Auth/GitHubSuccess";
import GitHubFailure from "Routes/Auth/GitHubFailure";
import SendLink from "Routes/Auth/SendLink";
import PropTypes from "prop-types";
import Loadable from "react-loadable";
import GlobalPreloader from "Shared/GlobalPreloader";
import ErrorBoundry from "Shared/Errors/ErrorBoundry";
import AuthLayout from "./Layout";

const AuthRoutes = props => {
  const { match } = props;
  return (
    <AuthLayout>
      <ErrorBoundry>
        <Switch>
          <Route exact path={`${match.url}/login`} component={Login} />
          <Route exact path={`${match.url}/register`} component={Register} />
          <Route
            exact
            path={`${match.url}/register/:email`}
            component={Register}
          />
          <Route exact path={`${match.url}/resetlink`} component={SendLink} />
          <Route exact path={`${match.url}/reset/:token`} component={Reset} />
          <Route
            exact
            path={`${match.url}/github_success`}
            component={GitHubSuccess}
          />
          <Route
            exact
            path={`${match.url}/github_failure`}
            component={GitHubFailure}
          />
          <Route
            component={Loadable({
              loader: () => import("Shared/Errors/404"),
              loading: GlobalPreloader
            })}
          />
        </Switch>
      </ErrorBoundry>
    </AuthLayout>
  );
};
AuthRoutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }).isRequired
};
export default () => AuthRoutes;

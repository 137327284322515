import Api from "Api";
import HttpClient from "Utils/HttpClient";
import uuid from "uuid";
import {
  isEmpty,
  getMatchingPlan,
  stringifyLaravelErrors,
  planPrices,
  planTitles
} from "Utils/helpers";
import { createNotification } from "Store/modules/common/notifications/actions";
import { openModal } from "Store/modules/common/modal/actions";
import {
  setStep,
  setSurveyChoice,
  setBlock as setTrialBlock
} from "Store/modules/common/trial/actions";
import {
  setUserData,
  loadUserFromToken
} from "Store/modules/common/auth/actions";
import { get } from "lodash";
import { push } from "connected-react-router";
import * as actionTypes from "./actionTypes";

export const setLoading = payload => ({
  type: actionTypes.LOADING,
  payload
});

export const setLocking = payload => ({
  type: actionTypes.LOCKING,
  payload
});

export const setProfileLocking = payload => ({
  type: actionTypes.PROFILE_LOCKING,
  payload
});

export const changeTab = payload => ({
  type: actionTypes.SET_TAB_VALUE,
  payload
});

export const setCreditToken = payload => ({
  type: actionTypes.SET_CREDIT_TOKEN,
  payload
});

export const setInvoices = payload => ({
  type: actionTypes.SET_INVOICES_LIST,
  payload
});

export const setPaginateData = payload => ({
  type: actionTypes.SET_PAGINATE_DATA,
  payload
});

export const setProfilePicUrl = payload => ({
  type: actionTypes.SET_PIC_URL,
  payload
});

export const addAndUpgradeStatus = payload => ({
  type: actionTypes.ADD_AND_UPGRADE,
  payload
});

export const getUserFromDB = () => dispatch => {
  dispatch(setLocking(true));

  // get request for adding the credit card to get the token
  HttpClient.get(Api.getUserAccount)
    .then(res => {
      if (res.status === 200) {
        dispatch(setUserData(res.data.user));
      }

      dispatch(setLocking(false));
    })
    .catch(error => {
      console.log(error);
      dispatch(setLocking(false));
    });
};

export const removePic = userId => (dispatch, getState) => {
  dispatch(setLocking(true));

  // get request for adding the credit card to get the token
  HttpClient.delete(Api.removePic(userId))
    .then(res => {
      if (res.data.success) {
        const { user } = getState().auth;

        dispatch(
          setUserData({
            ...user,
            picture: null
          })
        );
        dispatch(
          createNotification({
            message: "picture was removed successfully",
            color: "success",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
      } else {
        dispatch(
          createNotification({
            message: "picture wasn't removed",
            color: "danger",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
      }

      dispatch(setLocking(false));
    })
    .catch(error => {
      console.log(error);
    });
};

export const handleUploadImage = (file, userId) => (dispatch, getState) => {
  dispatch(setLocking(true));

  const getFile = file.target.files[0];
  const data = new FormData();
  data.append("file", file.target.files[0]);
  HttpClient.post(Api.changeProfilePic(userId), data)
    .then(response => {
      if (response.data.success) {
        const { user } = getState().auth;
        const reader = new FileReader();

        // update the pic in the front end NOW
        reader.onloadend = () => {
          dispatch(
            setUserData({
              ...user,
              picture: reader.result
            })
          );
        };

        reader.readAsDataURL(getFile);

        dispatch(
          createNotification({
            message: response.data.msg
              ? response.data.msg
              : "picture was updated!",
            color: "success",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
      } else {
        dispatch(setProfilePicUrl(""));
        dispatch(
          createNotification({
            message: response.data.msg ? response.data.msg : "server error!",
            color: "danger",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
      }
      dispatch(setLocking(false));
    })
    .catch(error => {
      console.log(error);
    });
};

export const getInvoicesList = pageNum => dispatch => {
  dispatch(setLocking(true));
  // get request for adding the credit card to get the token
  HttpClient.get(Api.getInvoices(pageNum))
    .then(res => {
      dispatch(setInvoices(res.data.invoices.data));
      dispatch(
        setPaginateData({
          currentPage: res.data.invoices.current_page,
          lastPage: res.data.invoices.last_page,
          totalCount: res.data.invoices.total,
          perPage: res.data.invoices.per_page,
          from: res.data.invoices.from,
          to: res.data.invoices.to
        })
      );
      dispatch(setLocking(false));
    })
    .catch(error => {
      console.log(error);
    });
};

export const getCreditInfo = () => dispatch => {
  dispatch(setLocking(true));

  // get request for adding the credit card to get the token
  HttpClient.get(Api.addCreditCard)
    .then(res => {
      dispatch(setCreditToken(res.data.token));
      dispatch(setLocking(false));
    })
    .catch(error => {
      console.log(error);
    });
};

export const addCardAndUpgrade = (payload, type) => (dispatch, getState) => {
  dispatch(setLocking(true));
  const requestPayload = {
    payment_method_nonce: payload
  };
  if (type === "paypal") {
    requestPayload.email = payload.details.email;
    requestPayload.payment_method_nonce = payload.nonce;
  }
  HttpClient.post(
    Api.planSub(getState().accountSettings.addUpgradeObj.planId),
    requestPayload
  )
    .then(res => {
      if (res.data.success) {
        dispatch(changeTab(2));

        if (!isEmpty(res.data.user)) {
          dispatch(
            setUserData({
              ...res.data.user
            })
          );
        }

        dispatch(
          createNotification({
            message: res.data.msg,
            color: "success",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );

        // change it back add card , that's responisble for the functionality in the creditCard.jsx component to
        // addCard OR addAndUpgrade in case there is no cards
        dispatch(
          addAndUpgradeStatus({
            status: false,
            planId: null
          })
        );
        dispatch(setLocking(false));
      } else {
        dispatch(
          createNotification({
            message: res.data.errors
              ? stringifyLaravelErrors(res.data.errors)
              : "",
            color: "danger",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
        dispatch(setLocking(false));
      }
    })
    .catch(error => {
      console.log(error);
      dispatch(setLocking(false));
      createNotification({
        message:
          "Something went wrong while processing your info, please try again",
        color: "danger",
        close: true,
        autoHideDuration: 3000
      });
    });
};

export const addCreditCard = (payload, type) => (dispatch, getState) => {
  dispatch(setLocking(true));
  const requestPayload = {
    payment_method_nonce: payload
  };
  if (type === "paypal") {
    requestPayload.email = payload.details.email;
    requestPayload.payment_method_nonce = payload.nonce;
  }
  HttpClient.post(Api.addCreditCard, requestPayload)
    .then(res => {
      if (res.data.added) {
        console.log("backend card", res.data);
        const { user } = getState().auth;
        if (res.data.user) {
          dispatch(
            setUserData({
              ...user,
              ...res.data.user
            })
          );
        }
        dispatch(changeTab(2));

        dispatch(setLocking(false));
        dispatch(
          createNotification({
            message: "Payment method has been added successfully!",
            color: "success",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
      } else {
        dispatch(setLocking(false));
        dispatch(
          createNotification({
            message: res.data.errors
              ? stringifyLaravelErrors(res.data.errors)
              : "",
            color: "danger",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
      }
    })
    .catch(error => {
      console.log(error);
      dispatch(setLocking(false));
      createNotification({
        message:
          "Something went wrong while processing your info, please try again",
        color: "danger",
        close: true,
        autoHideDuration: 3000
      });
    });
};
export const deleteCard = id => dispatch => {
  dispatch(setLocking(true));

  // get request for adding the credit card to get the token
  HttpClient.delete(Api.deleteCard(id))
    .then(res => {
      if (res.data.success) {
        // remove the card from the list
        dispatch(
          setUserData({
            ...res.data.user
          })
        );
        dispatch(
          createNotification({
            message: "Card was deleted!",
            color: "success",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
        dispatch(setLocking(false));
      } else {
        // if (res.data.code === 401) {
        //   Intercom("trackEvent", "delete-card-active-sub");
        // }
        dispatch(
          createNotification({
            message: res.data.errors
              ? stringifyLaravelErrors(res.data.errors)
              : "Card deletion failed!",
            color: "danger",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
        dispatch(setLocking(false));
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export const setDefaultCard = id => (dispatch, getState) => {
  const { user } = getState().auth;
  // get request for adding the credit card to get the token
  dispatch(setLocking(true));

  HttpClient.get(Api.defaultCard(id))
    .then(res => {
      if (res.data.success) {
        const updatedUser = {
          ...user,
          defaultcc: id
        };

        dispatch(setUserData(updatedUser));
        dispatch(setLocking(false));
        dispatch(
          createNotification({
            message: "Default card updated!",
            color: "success",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
      } else {
        dispatch(setLocking(false));
        dispatch(
          createNotification({
            message: "Default card update failed!",
            color: "danger",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export const updateProfile = profileObj => (dispatch, getState) => {
  dispatch(setProfileLocking(true));
  const { user } = getState().auth;
  HttpClient.post(Api.profileUpdate, profileObj)
    .then(res => {
      dispatch(setProfileLocking(false));
      if (res.data.success) {
        console.log("supposed to update profile notification");
        dispatch(
          setUserData({
            ...user,
            ...profileObj
          })
        );
        dispatch(
          createNotification({
            message: "Profile info updated!",
            color: "success",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
      } else {
        dispatch(
          createNotification({
            message: "couldn't update your changes!",
            color: "danger",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
      }
    })
    .catch(error => {
      console.log(error);
      dispatch(setProfileLocking(false));
    });
};

export const changePassword = passwordObj => dispatch => {
  dispatch(setLocking(true));
  HttpClient.post(Api.updatePassword, passwordObj)
    .then(res => {
      dispatch(setLocking(false));
      if (res.data.code === 200) {
        dispatch(
          createNotification({
            message: res.data.message || "Password updated successfully",
            color: "success",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
      } else {
        dispatch(
          createNotification({
            message: res.data.message || "Failed to update your password",
            color: "danger",
            close: true,
            place: "bl",
            autoHideDuration: 3000
          })
        );
      }
    })
    .catch(error => {
      console.log(error);
      dispatch(setLocking(false));
      dispatch(
        createNotification({
          message: "Something went wrong",
          color: "danger",
          close: true,
          place: "bl",
          autoHideDuration: 3000
        })
      );
    });
};

export const addPaymentFirst = payload => dispatch => {
  dispatch(setLocking(true));
  dispatch(
    addAndUpgradeStatus({
      status: true,
      planId: payload
    })
  );

  dispatch(changeTab(1));

  dispatch(setLocking(false));
};

export const successPlanUpdate = payload => (dispatch, getState) => {
  dispatch(
    setUserData({
      ...getState().auth.user,
      plan_id: payload.planId
    })
  );
  dispatch(
    createNotification({
      message: payload.msg,
      color: "success",
      close: true,
      place: "bl",
      autoHideDuration: 3000
    })
  );
  dispatch(setLocking(false));
};

export const failedPlanUpdate = payload => dispatch => {
  dispatch(
    createNotification({
      message: payload.errors ? stringifyLaravelErrors(payload.errors) : "",
      color: "danger",
      close: true,
      place: "bl",
      autoHideDuration: 3000
    })
  );
  dispatch(setLocking(false));
};

export const upgradeToPro = (planId, defaultcc) => (dispatch, getState) => {
  dispatch(setLocking(true));
  // check for if there's a default cc
  if (isEmpty(defaultcc) || isEmpty(getState().auth.user.cards)) {
    dispatch(addPaymentFirst(planId));
  } else {
    HttpClient.post(Api.planSub(planId), {
      cc: defaultcc
    })
      .then(res => {
        // handle the if there's some error
        if (!res.data.success) {
          dispatch(
            createNotification({
              message: res.data.errors
                ? stringifyLaravelErrors(res.data.errors)
                : "",
              color: "danger",
              close: true,
              place: "bl",
              autoHideDuration: 3000
            })
          );
        } else {
          // handle the success

          dispatch(
            setUserData({
              ...getState().auth.user,
              plan_id: planId
            })
          );

          dispatch(
            createNotification({
              message: res.data.msg,
              color: "success",
              close: true,
              place: "bl",
              autoHideDuration: 3000
            })
          );
        }

        dispatch(setLocking(false));
      })
      .catch(err => {
        console.log(err);
      });
  }
};

export const downgradeTofree = payload => (dispatch, getState) => {
  dispatch(setTrialBlock(true));
  HttpClient.get(Api.unsubPlan)
    .then(res => {
      console.log("unsub", res.data);
      dispatch(setTrialBlock(false));
      // handle the success
      dispatch(setStep("Done"));
      dispatch(
        setUserData({
          ...getState().auth.user,
          plan_id: res.data.plan_id,
          trial_status: false
        })
      );
      dispatch(setSurveyChoice(payload));
      dispatch(
        createNotification({
          message: res.data.msg,
          color: "success",
          close: true,
          place: "bl",
          autoHideDuration: 3000
        })
      );
      dispatch(loadUserFromToken());
      dispatch(push("/"));
    })
    .catch(err => {
      console.log(err);
    });
};

export const accountModalToggle = payload => ({
  type: actionTypes.ACCOUNT_MODAL_TOGGLE,
  payload
});

export const openAccountSettings = (tab = "AccountSettings", customPlan) => (
  dispatch,
  getState
) => {
  // console.log("account settings modal");
  const matchingPlan =
    customPlan ||
    getMatchingPlan(
      get(getState().auth.user, ["devices", "t"])
        ? getState().auth.user.devices.t
        : 0
    );

  const planPrice = planPrices[matchingPlan];

  switch (tab) {
    case "AccountSettings":
      dispatch(
        openModal({
          id: uuid.v4(),
          type: "async",
          loader: () =>
            import(
              /* webpackChunkName: "js/account-modal" */ "Routes/Account/containers/Account.jsx"
            )
        })
      );
      dispatch(changeTab(0));
      break;
    case "Upgrade": {
      if (matchingPlan !== "enterprise") {
        dispatch(
          openModal({
            id: uuid.v4(),
            type: "async",
            loader: () =>
              import(
                /* webpackChunkName: "js/account-modal" */ "Routes/Account/containers/Account.jsx"
              )
          })
        );
        if (getState().auth.user.plan_id !== "free") {
          dispatch(dispatch(changeTab(4)));
        }
        if (isEmpty(getState().auth.user.defaultcc)) {
          dispatch(addPaymentFirst(matchingPlan));
        } else if (!isEmpty(getState().auth.user.defaultcc)) {
          dispatch(
            openModal({
              testId: "modal-plan-upgrade",
              type: "confirmation",
              onConfirm: () => {
                dispatch(
                  upgradeToPro(matchingPlan, getState().auth.user.defaultcc)
                );
              },
              title: "Upgrade Plan?",
              okLabel: `Upgrade to
                  ${planTitles[matchingPlan]}`,
              message: `Do you want to upgrade to ${
                planTitles[matchingPlan]
              } for $${planPrice}`
            })
          );

          dispatch(changeTab(4));
        }
      } else {
        Intercom("showMessages");
      }
      break;
    }

    case "Bills":
      dispatch(
        openModal({
          id: uuid.v4(),
          type: "async",
          loader: () =>
            import(
              /* webpackChunkName: "js/account-modal" */ "Routes/Account/containers/Account.jsx"
            )
        })
      );
      dispatch(changeTab(3));
      break;
    case "Payment":
      dispatch(
        openModal({
          id: uuid.v4(),
          type: "async",
          loader: () =>
            import(
              /* webpackChunkName: "js/account-modal" */ "Routes/Account/containers/Account.jsx"
            )
        })
      );
      dispatch(changeTab(2));
      break;
    case "Custom":
      dispatch(
        openModal({
          id: uuid.v4(),
          type: "async",
          loader: () =>
            import(
              /* webpackChunkName: "js/account-modal" */ "Routes/Account/containers/Account.jsx"
            )
        })
      );
      dispatch(changeTab(5));
      break;
    default:
  }
};

export const updateBillingEmailsState = payload => ({
  type: actionTypes.SET_BILLING_EMAILS_STATE,
  payload
});

export const updateBillingEmails = payload => dispatch => {
  dispatch(setLocking(true));
  HttpClient.post(Api.profileUpdate, {
    emailPaymentSub: payload
  })
    .then(res => {
      console.log(res);
      dispatch(setLocking(false));
      dispatch(updateBillingEmailsState(payload));
    })
    .catch(err => {
      console.log(err);
      dispatch(setLocking(false));
      dispatch(
        createNotification({
          message: "Couldn't update your settings, please try again later.",
          color: "danger",
          close: true,
          autoHideDuration: 3000
        })
      );
    });
};

export const registerStudentLogin = temp => (dispatch, getState) => {
  const { user } = getState().auth;

  if (temp) {
    dispatch(
      setUserData({
        ...user,
        first_github_login: true
      })
    );
  } else {
    dispatch(setLocking(true));
    HttpClient.post(Api.profileUpdate, {
      first_github_login: true
    })
      .then(res => {
        console.log(res);
        dispatch(setLocking(false));
        dispatch(
          setUserData({
            ...user,
            first_github_login: true
          })
        );
      })
      .catch(err => {
        console.log(err);
        dispatch(setLocking(false));
        dispatch(
          createNotification({
            message: "Something went wrong, please try again later.",
            color: "danger",
            close: true,
            autoHideDuration: 3000
          })
        );
      });
  }
};

import Api from "Api";
import { httpClient } from "Utils";
import { __TOKEN_KEY__ } from "Utils/HttpClient";
import { push } from "connected-react-router";
import { createNotification as notify } from "Store/modules/common/notifications/actions";
import { resetState } from "Store/reset";
import * as actionTypes from "./actionTypes";

const __AUTH_DATA__ = "__AUTH_DATA__";
export const authenticate = payload => ({
  type: actionTypes.AUTHENTICATE,
  payload
});
export const fetchingAuthData = payload => ({
  type: actionTypes.FETCHING_AUTH_DATA,
  payload
});

export const setUserData = payload => ({
  type: actionTypes.SET_USER,
  payload
});

export const setLocking = payload => ({
  type: actionTypes.LOCKING,
  payload
});

export const toggleIsEmailSent = payload => ({
  type: actionTypes.EMAIL_SENT,
  payload
});

const loginSuccess = ({ data }, dispatch) => {
  localStorage.setItem(__TOKEN_KEY__, data.access_token);
  localStorage.setItem(__AUTH_DATA__, data);
  dispatch(
    authenticate({
      ...data,
      isAuthenticated: true
    })
  );
  dispatch(
    notify({
      message: `Welcome back ${data.user.name}`,
      color: "primary"
    })
  );

  dispatch(setLocking(false));
};

const loginFailed = (err, dispatch) => {
  if (
    err.response &&
    Object.prototype.hasOwnProperty.call(err.response, "data") &&
    Object.prototype.hasOwnProperty.call(err.response.data, "error")
  ) {
    dispatch(
      notify({
        message: err.response.data.error || "",
        color: "danger",
        open: true,
        place: "bc"
      })
    );
  }
  dispatch(setLocking(false));
};

export const setLoginSource = payload => ({
  type: actionTypes.SET_LOGIN_SOURCE,
  payload
});

export const socialLogin = (accessToken = {}, socialProvider) => dispatch => {
  dispatch(setLocking(true));
  dispatch(setLoginSource(socialProvider));
  httpClient
    .post(Api.auth.socialLogin(socialProvider), {
      accessToken
    })
    .then(res => loginSuccess(res, dispatch))
    .catch(err => loginFailed(err, dispatch));
};

export const login = (payload, cb) => dispatch => {
  dispatch(setLocking(true));
  dispatch(setLoginSource("local"));
  httpClient
    .post(Api.auth.login, payload)
    .then(res => {
      loginSuccess(res, dispatch);
      cb(true);
    })
    .catch(err => {
      loginFailed(err, dispatch);
      cb(false, err && err.response ? err.response.data.error : "");
    });
};

export const register = (payload, cb) => dispatch => {
  dispatch(setLocking(true));
  httpClient
    .post(Api.auth.register, payload)
    .then(res => {
      loginSuccess(res, dispatch);
      cb(true);
    })
    .catch(err => {
      loginFailed(err, dispatch);
      cb(false, err && err.response ? err.response.data.error : "");
    });
};

export const sendEmail = (payload, cb) => dispatch => {
  dispatch(setLocking(true));
  httpClient
    .post(Api.auth.email, payload)
    .then(({ data }) => {
      console.log(data);
      dispatch(toggleIsEmailSent(true));
      cb(true);
      dispatch(setLocking(false));
    })
    .catch(err => {
      dispatch(
        notify({
          open: true,
          place: "bc",
          message: err.response.data.email || "",
          color: "danger"
        })
      );
      cb(false, err.response.data.email || "");
      dispatch(setLocking(false));
    });
};

export const reset = (payload, cb) => dispatch => {
  dispatch(setLocking(true));
  httpClient
    .post(Api.auth.reset, payload)
    .then(({ data }) => {
      dispatch(
        notify({
          open: true,
          place: "tc",
          color: "success",
          message: data.message || `password changed!`
        })
      );
      cb(true);
      dispatch(setLocking(false));
    })
    .catch(e => {
      dispatch(
        notify({
          open: true,
          place: "bc",
          message: e.response.data.error || "",
          color: "danger"
        })
      );
      cb(false, e.response.data.error || "");
      dispatch(setLocking(false));
    });
};

export const logout = () => dispatch => {
  httpClient.post(Api.auth.logout, {}).then(() => {
    localStorage.removeItem(__TOKEN_KEY__);
    localStorage.removeItem(__AUTH_DATA__);
    dispatch(
      authenticate({
        user: null,
        isAuthenticated: false,
        redirect: false
      })
    );
    dispatch(resetState());
    if (window.location.search) {
      dispatch(push(`/auth/login${window.location.search}`));
      // dispatch(push(`/auth/login`));
    } else {
      dispatch(push(`/auth/login`));
    }
    if (window.Intercom) {
      window.Intercom("shutdown");
      delete window.Intercom;
    }
  });
};

export const refresh = payload => dispatch => {
  // if (payload) {
  httpClient.setAuthHeader(payload);
  httpClient
    .post(Api.auth.refresh, {})
    .then(({ data }) => {
      if (data.access_token) {
        localStorage.setItem(__TOKEN_KEY__, data.access_token);
        localStorage.setItem(__AUTH_DATA__, data);
        dispatch(
          authenticate({
            ...data,
            isAuthenticated: true
          })
        );
      } else {
        localStorage.removeItem(__TOKEN_KEY__);
        localStorage.removeItem(__AUTH_DATA__);
        dispatch(
          authenticate({
            user: {},
            isAuthenticated: false
          })
        );
      }
      dispatch(fetchingAuthData(false));
    })
    .catch(() => {
      dispatch(fetchingAuthData(false));
    });
  // }
};

export const loadUserFromToken = () => (dispatch, getState) => {
  const {
    router: {
      location: { search }
    }
  } = getState();

  let token;
  try {
    token = localStorage.getItem(__TOKEN_KEY__);
  } catch (e) {
    if (
      e &&
      Object.prototype.hasOwnProperty.call(e, "name") &&
      e.name === "NS_ERROR_FILE_CORRUPTED"
    ) {
      dispatch(
        notify({
          message:
            "Sorry, it looks like your browser storage has been corrupted. Please clear your storage by going to Tools -> Clear Recent History -> Cookies and set time range to 'Everything'. This will remove the corrupted browser storage across all sites.",
          color: "danger",
          close: true
        })
      );
    }
  }

  if (search.includes("token=")) {
    window.location.href = "/";
    token = search.split("?token=")[1]; // eslint-disable-line
    localStorage.setItem(__TOKEN_KEY__, token);
  }
  if (!token || token === "") {
    if (window.location.search) {
      push(`/auth/login${window.location.search}`);
    } else {
      const redirectTo =
        window.location.pathname + (window.location.search || "");
      push(`/auth/login${redirectTo && `?redirectTo=${redirectTo}`}`);
    }
  }
  if (window.navigator.onLine) {
    dispatch(refresh(token));
  }
};

export const setClearbitData = payload => ({
  type: actionTypes.SET_CLEARBIT_DATA,
  payload
});

export const getClearbitData = payload => dispatch => {
  dispatch(setLocking(true));
  httpClient
    .get(Api.clearbit(payload))
    .then(res => {
      // console.log(res.data)
      dispatch(setLocking(false));
      if (res && res.data && res.data.data) {
        dispatch(
          setClearbitData({
            name: `${res.data.data.f_name} ${res.data.data.l_name}`,
            avatar: res.data.data.avatar,
            email: res.data.data._id
          })
        );
      }
    })
    .catch(() => {
      dispatch(setLocking(false));
    });
};

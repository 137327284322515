import Loadable from "react-loadable";
import PlaceHolder from "./components/PlaceHolder";
import { injectReducer } from "../../store/makeRootReducer";

export default store =>
  Loadable({
    loader: () =>
      injectReducer(store, {
        key: "pollAnalytics",
        getReducer: () =>
          import(/* webpackChunkName: "js/pollAnalyticsReducer" */ "./modules/reducers")
      }).then(() =>
        import(/* webpackChunkName: "js/pollAnalytics" */ "./containers/PollAnalyticsContainer")
      ),
    loading: PlaceHolder
  });

import React from "react";
import { Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import GlobalPreloader from "Shared/GlobalPreloader";
import ProtectedRoute from "Shared/AuthRoute";
import accountInvoiceRoute from "Routes/AccountInvoice";
import dashboardRoute from "../layouts/Dashboard";
import authRoute from "../layouts/Auth";
import AutoGithub from "../layouts/Auth/AutoGithub";

const createRoutes = store => (
  <Switch>
    <ProtectedRoute
      path="/account/invoice/:invoiceId"
      component={props => {
        const AccountInvoice = accountInvoiceRoute(store);
        return <AccountInvoice {...props} />;
      }}
    />
    <Route
      path="/auth"
      render={props => {
        const Auth = authRoute(store);
        return <Auth {...props} />;
      }}
    />
    <Route path="/github" render={props => <AutoGithub {...props} />} />
    <ProtectedRoute
      path="/"
      render={props => {
        const Dashboard = dashboardRoute(store);
        return <Dashboard {...props} />;
      }}
    />
    <Route
      component={Loadable({
        loader: () => import("Shared/Errors/404"),
        loading: GlobalPreloader
      })}
    />
  </Switch>
);

export default createRoutes;

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  slider: {
    position: "relative",
    padding: "15px 30px",
    margin: "0 auto",
    maxWidth: 600,
    overflow: "hidden",
    borderRadius: 5,
    "&:before": {
      content: "''",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      opacity: 0.5,
      zIndex: 1,
      backgroundColor: theme.palette.primary.dark
    },
    "@media(max-width: 600px)": {
      padding: "15px 40px"
    }
  },
  slidesWrapper: {
    position: "relative",
    minHeight: 100,
    "@media(max-width: 600px)": {
      minHeight: 200
    }
  },
  slide: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "-100%",
    width: "100%",
    opacity: 0,
    transition: "all .3s ease",
    zIndex: -1,
    "&.active": {
      left: 0,
      opacity: 1,
      zIndex: 9
    },
    "@media(max-width: 600px)": {
      flexDirection: "column"
    }
  },
  image: {
    height: "auto",
    // flex: "150px",
    maxWidth: 150,
    borderRadius: "50%",
    overflow: "hidden",
    display: "block",
    "@media(max-width: 600px)": {
      width: 75
    },
    "& img": {
      maxWidth: "100%",
      objectFit: "contain",
      display: "block"
    }
  },
  textDetails: {
    marginLeft: 15,
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: "1.35417em",
    "& > p": {
      margin: "0 0 5px 0",
      fontSize: "1rem"
    },
    "& > small": {
      opacity: 0.7,
      "&:before": {
        content: "'\\2014   \\A0'"
      }
    },
    "@media(max-width: 600px)": {
      marginLeft: 0,
      marginTop: 15,
      "& > p": {
        fontSize: ".8rem"
      }
    }
  },
  navButton: {
    border: "none",
    background: "none",
    boxShadow: "none",
    outline: "none",
    cursor: "pointer",
    fontSize: "1.7rem",
    zIndex: 10,
    color: theme.palette.primary.contrastText,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%) scale(.9)",
    transition: "transform .2s ease",
    "&:hover": {
      transform: "translateY(-50%) scale(1)"
    },
    "&.prev": {
      left: 5
    },
    "&.next": {
      right: 5
    }
  },
  title: {
    textAlign: "center",
    marginBottom: 30
  }
});

class QuotesSlider extends Component {
  state = {
    interval: null,
    slide: 0,
    slides: [
      {
        who: "Pietro Saccomani. Mobiloud, CEO.",
        text:
          "Over 5 million people using apps created by MobiLoud have been served with push notifications through PushBots.",
        img:
          "https://d1qb2nb5cznatu.cloudfront.net/users/239071-large?1405574350"
      },
      {
        who: "Raoul Schrievers. Gainsfire, Founder.",
        text:
          "PushBots is a great tool for staying connected to our users. We love PushBot's intuitive interface and flexibility in use.",
        img: "/assets/raoul-schrievers.jpg"
      }
    ]
  };

  componentDidMount() {
    const interval = setInterval(() => {
      this.setState(state => {
        const slide =
          state.slide + 1 >= state.slides.length ? 0 : state.slide + 1;
        return { slide };
      });
    }, 3000);
    this.setState({ interval });
  }

  componentWillUnmount() {
    this.clearAutoplay();
  }

  clearAutoplay = () => {
    clearInterval(this.state.interval);
    this.setState({ interval: null });
  };

  goPrev = () => {
    this.clearAutoplay();
    this.setState(state => {
      const slide = state.slide - 1 <= 0 ? 0 : state.slide - 1;
      return { slide };
    });
  };

  goNext = () => {
    this.clearAutoplay();
    this.setState(state => {
      const slide =
        state.slide + 1 >= state.slides.length ? state.slide : state.slide + 1;
      return { slide };
    });
  };

  fixImage = e => {
    e.target.style = "display: block";
  };

  render() {
    const { classes } = this.props;
    const { slide, slides } = this.state;
    return (
      <>
        <Typography variant="h6" color="inherit" className={classes.title}>
          Discover why people love PushBots
        </Typography>
        <div className={classes.slider} onClick={this.clearAutoplay}>
          <button
            type="button"
            className={`${classes.navButton} prev`}
            onClick={this.goPrev}
          >
            <i className="far fa-angle-left" />
          </button>
          <div className={classes.slidesWrapper}>
            {slides.map((item, index) => (
              <div
                key={item.img}
                className={`${classes.slide} ${
                  slide === index ? "active" : ""
                }`}
              >
                <div className={classes.image}>
                  <img
                    src={item.img}
                    alt={item.who}
                    // onLoad={this.fixImage}
                  />
                </div>
                <div className={classes.textDetails}>
                  <p>{item.text}</p>
                  <small>{item.who}</small>
                </div>
              </div>
            ))}
          </div>
          <button
            type="button"
            className={`${classes.navButton} next`}
            onClick={this.goNext}
          >
            <i className="far fa-angle-right" />
          </button>
        </div>
      </>
    );
  }
}

QuotesSlider.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(QuotesSlider);

import initialState from "Store/initialState";
import * as actionTypes from "./actionTypes";

const rootReducer = (state = initialState.trial, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionTypes.RESET:
      return initialState.trial;
    case actionTypes.SET_DATA:
      return {
        ...state,
        ...action.payload
      };
    case actionTypes.SET_BLOCK:
      return {
        ...state,
        block: action.payload
      };
    case actionTypes.SET_STEP:
      return {
        ...state,
        step: action.payload
      };

    case actionTypes.SHOW_TRIAL_MODAL:
      return {
        ...state,
        showModal: action.payload,
        step: "Landing1"
      };
    case actionTypes.SET_SURVEY_CHOICE:
      return {
        ...state,
        surveyChoice: action.payload
      };
    case actionTypes.SET_AMOUNT:
      return {
        ...state,
        amount: action.payload
      };
    case actionTypes.SET_CC_DATA:
      return {
        ...state,
        cc: {
          ...state.cc,
          ...action.payload
        }
      };
    default:
      return state;
  }
};
export default rootReducer;

import React from "react";
import Grid from "@material-ui/core/Grid";
import { RectShape } from "react-placeholder/lib/placeholders";
import "react-placeholder/lib/reactPlaceholder.css";

const PlaceHolder = () => (
  <Grid container spacing={16} className="show-loading-animation">
    <Grid item xs={12} md={12}>
      <Grid container spacing={16} justify="center">
        <Grid item xs={12} md={12}>
          <RectShape
            color="lightgray"
            style={{ width: "100%", height: "50px" }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <RectShape
            color="lightgray"
            style={{ width: "100%", height: "200px" }}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default PlaceHolder;

// Import External Dependencies
import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import DocumentTitle from "react-document-title";

class PageRoute extends React.PureComponent {
  state = {
    title: ""
  };

  constructor(props) {
    super(props);
    if (props.beforeEnter) {
      props.beforeEnter({
        ...this.props,
        match: this.props.computedMatch,
        useTitle: this.useTitle.bind(this)
      });
    }
  }

  componentWillMount() {
    if (this.props.onLoad) {
      this.props.onLoad({ ...this.props, match: this.props.computedMatch });
    }
  }

  componentWillUnmount() {
    if (this.props.beforeLeave) {
      this.props.beforeLeave({
        ...this.props,
        match: this.props.computedMatch
      });
    }
  }

  useTitle(appTitle) {
    this.setState({ title: appTitle });
  }

  render() {
    const { component: Component, render, ...props } = this.props;
    return (
      <Route
        {...props}
        render={routeProps => (
          <DocumentTitle
            title={this.state.title ? this.state.title : props.title}
          >
            {Component ? (
              <Component useTitle={this.useTitle} {...routeProps} />
            ) : (
              render({ ...routeProps, useTitle: this.useTitle.bind(this) })
            )}
          </DocumentTitle>
        )}
      />
    );
  }
}

PageRoute.propTypes = {
  onLoad: PropTypes.func,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(React.Component)
  ]),
  render: PropTypes.func,
  title: PropTypes.string,
  computedMatch: PropTypes.shape({}),
  beforeEnter: PropTypes.func,
  beforeLeave: PropTypes.func
};

PageRoute.defaultProps = {
  component: null,
  onLoad: null,
  render: null,
  title: "PushBots",
  computedMatch: null,
  beforeEnter: null,
  beforeLeave: null
};
export default PageRoute;

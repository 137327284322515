import { teal } from "@material-ui/core/colors";
import {
  devicesTableColumns,
  initialFilteredDevicesTableColumns
} from "./modules/lib";

const devicesTableColumnsNames = devicesTableColumns
  .map(col => col.name)
  .filter(col => initialFilteredDevicesTableColumns.indexOf(col) === -1);

export default {
  auth: {
    isAuthenticated: false,
    fetchingAuthData: true,
    old_plan: false,
    user: {
      loading: {
        spinnerName: "oval",
        message: "We’ll be right with you…"
      }
    },
    redirect: false,
    lock: false,
    isResetEmailSent: false,
    clearbitData: {},
    loginSource: "local"
  },
  accountSettings: {
    loading: false,
    lock: false,
    profilelock: false,
    token: "",
    cards: [],
    tab: 0,
    invoices: [],
    paginationData: {},
    matchingPlan: "n49",
    devices: 0,
    upgradeConfirmDialog: false,
    addUpgradeObj: {
      status: false,
      planId: null
    },
    accountModalStatus: false,
    billingEmails: false
  },
  trial: {
    loading: true,
    block: false,
    lock: false,
    showModal: false,
    step: "Landing1",
    cc: {
      number: "",
      name: "",
      expiry: "",
      cvv: ""
    },
    matchingPlan: "n49",
    token: "",
    devices: 0,
    surveyChoice: ""
  },
  modal: { modals: [], zIndex: 1300 },
  notifications: [],
  search: {
    apps: []
  },
  ui: {
    TopBarComponent: () => null,
    themeColor: teal,
    currentAppTitle: "",
    currentAppId: "",
    devices: {
      visibleTableColumns: devicesTableColumnsNames,
      initialFilters: [
        {
          field: "ls",
          value: 30,
          operatorIndex: 3
        }
      ]
    },
    hideStudentMessage: false,
    upgradeBoxVisible: false,
    upgradeBoxContent: {
      mainThree: [],
      featuresList: [],
      videoUrl: "",
      title: "",
      desc: "",
      price: 49,
      submitButtonText: "Start your trial",
      onClick: () => {}
    },
    themeManuallyChanged: false
  },
  appSettings: {
    loading: true,
    lock: false,
    app: {},
    icon: "",
    tab: "General",
    webBlock: false
  },
  taskRunner: {
    tasks: []
  }
};

import initialState from "Store/initialState";
import * as actionTypes from "./actionTypes";

export default (state = initialState.ui, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.TOP_BAR_COMPONENT:
      return {
        ...state,
        TopBarComponent: payload
      };
    case actionTypes.CHANGE_THEME_COLOR:
      return {
        ...state,
        themeColor: payload
      };
    case actionTypes.CHANGE_CURRENT_APP_DETAILS:
      return {
        ...state,
        currentAppTitle: payload.title,
        currentAppId: payload.appId,
        appTotalCount: payload.count
      };
    case actionTypes.CHANGE_DEVICES_LIST_COLUMNS:
      return {
        ...state,
        devices: {
          ...state.devices,
          visibleTableColumns: action.payload
        }
      };
    case actionTypes.CHANGE_DEVICES_INITIAL_FILTERS:
      return {
        ...state,
        devices: {
          ...state.devices,
          initialFilters: action.payload
        }
      };
    case actionTypes.HIDE_STUDENT_MESSAGE:
      return {
        ...state,
        hideStudentMessage: true
      };
    case actionTypes.SET_UPGRADE_BOX_VISIBLE:
      return {
        ...state,
        upgradeBoxVisible: action.payload
      };
    case actionTypes.SET_UPGRADE_BOX_CONTENT:
      return {
        ...state,
        upgradeBoxContent: {
          ...state.upgradeBoxContent,
          ...action.payload
        }
      };
    case actionTypes.SET_THEME_MANUALLY_CHANGED:
      return {
        ...state,
        themeManuallyChanged: action.payload
      };
    default:
      return state;
  }
};

import React from "react";
import Grid from "@material-ui/core/Grid";
import { RectShape } from "react-placeholder/lib/placeholders";
// import { get } from "lodash";
import { connect } from "react-redux";
import BlockUi from "react-block-ui";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = () => ({
  loading: {
    marginBottom: "0px",
    textAlign: "center"
  }
});

const PlaceHolder = props => {
  const { classes } = props;

  return (
    <BlockUi
      blocking
      tag="div"
      className="show-loading-animation"
      loader={
        <div className={classes.loading}>
          <div className="spinner">
            <div className="double-bounce1" />
            <div className="double-bounce2" />
          </div>
          {/* <img
            alt="Loading.."
            src={`/assets/svg/${get(user, "loading.spinnerName", "oval")
              .split(" ")
              .join("")}.svg`}
          />
          <h2>{get(user, "loading.message", "We’ll be right with you…")}</h2> */}
        </div>
      }
    >
      <Grid container spacing={16} className="show-loading-animation">
        <Grid item xs={12} md={4}>
          <RectShape
            color="lightgray"
            style={{ width: "100%", height: "50px" }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <RectShape
            color="lightgray"
            style={{ width: "100%", height: "80px" }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <RectShape
            color="lightgray"
            style={{ width: "100%", height: "80px" }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <RectShape
            color="lightgray"
            style={{ width: "100%", height: "80px" }}
          />
        </Grid>
      </Grid>
    </BlockUi>
  );
};

PlaceHolder.propTypes = {
  classes: PropTypes.object.isRequired
  // user: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(withStyles(styles)(PlaceHolder));

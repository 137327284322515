/*eslint-disable*/
import React, { Component } from "react";
import PropTypes from "prop-types";

const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export const IntercomAPI = (...args) => {
  if (canUseDOM && window.Intercom) {
    window.Intercom.apply(null, args);
  } else {
    console.warn("Intercom not initialized yet");
  }
};

export default class Intercom extends Component {
  static propTypes = {
    appID: PropTypes.string.isRequired
  };

  static displayName = "Intercom";

  constructor(props) {
    super(props);

    const { appID, plan_id, ...otherProps } = props;

    if (!appID || !canUseDOM) {
      return;
    }

    if (!window.Intercom) {
      (function(w, d, id, s, x) {
        function i() {
          i.c(arguments);
        }
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement("script");
        s.async = 1;
        s.src = `https://widget.intercom.io/widget/${id}`;
        d.head.appendChild(s);
      })(window, document, appID);
    }

    window.intercomSettings = { ...otherProps, app_id: appID };

    if (window.Intercom) {
      window.Intercom("boot", otherProps);
      if (plan_id === "free") {
        window.Intercom("update", {
          hide_default_launcher: true
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { appID, ...otherProps } = nextProps;

    if (!canUseDOM) return;

    window.intercomSettings = { ...otherProps, app_id: appID };

    if (window.Intercom) {
      window.Intercom("update", otherProps);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    if (!canUseDOM || !window.Intercom) return false;

    if (this.props.plan_id === "free") {
      window.Intercom("update", {
        hide_default_launcher: true
      });
    }

    // window.Intercom("shutdown");

    // delete window.Intercom;
  }

  render() {
    return false;
  }
}

import initialState from "Store/initialState";
import * as actionTypes from "./actionTypes";

export default (state = initialState.modal, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        modals: [
          ...state.modals,
          {
            id: Date.now(),
            zIndex: state.zIndex + state.modals.length + 1,
            ...payload
          }
        ]
      };
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        modals: state.modals.filter(item => item.id !== payload.id)
      };
    default:
      return state;
  }
};

import PropTypes from "prop-types";
import React from "react";
import "./404/style.css";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    if (window.Raven) {
      window.Raven.captureException(error, { extra: info });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          id="error-root"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="unicorn" />
          <div className="container">
            <div className="warning">
              <h2 className="h2">
                All those moments will be lost in time, like tears in rain.
              </h2>
              <p>Something went wrong , you may try</p>
              <a className="a" onClick={() => window.location.reload()}>
                Reload the page
              </a>
              <a className="a" onClick={() => Intercom("showMessages")}>
                Contact us
              </a>
            </div>

            <div className="footer">
              <p className="copyright p">&copy; 2018 Pushbots.com</p>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const LOGIN_ROOT = "/auth/login";
class AuthRoute extends React.PureComponent {
  componentWillMount() {
    if (this.props.onLoad) {
      this.props.onLoad({ ...this.props, match: this.props.computedMatch });
    }
  }

  render() {
    const {
      component: Comp,
      isAuthenticated,
      render,
      path,
      ...rest
    } = this.props;
    return (
      <Route
        path={path}
        {...rest}
        render={props => {
          if (isAuthenticated) {
            return render ? render(props) : <Comp {...props} />;
          }
          const redirectTo =
            window.location.pathname + (window.location.search || "");

          return (
            <Redirect
              to={{
                pathname: LOGIN_ROOT,
                state: {
                  prevLocation: path,
                  error: "You need to login first!"
                },
                search:
                  redirectTo && this.props.redirect
                    ? `?redirectTo=${redirectTo}`
                    : ""
              }}
            />
          );
        }}
      />
    );
  }
}
AuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  isAuthenticated: PropTypes.bool.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  render: PropTypes.func,
  onLoad: PropTypes.func,
  computedMatch: PropTypes.shape({}).isRequired,
  redirect: PropTypes.bool.isRequired
};

AuthRoute.defaultProps = {
  render: null,
  onLoad: null,
  component: null
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  redirect: state.auth.redirect
});

export default connect(mapStateToProps)(AuthRoute);
